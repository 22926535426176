import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  createProductCategory,
  updateProductCategory,
  uploadSingleDocument,
  getCollections,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import SelectField from "../../components/Select/Select";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    docId: data?.document?._id || "",
    name: data?.name || "",
    image: data?.document?.url || "",
    collections:
      data?.collections?.map((el) => ({
        label: el.title,
        value: el._id,
      })) || [],
    file: "",
  });
  const [collections, setCollections] = useState([]);

  const [errors, setErrors] = useState({
    docId: "",
    name: "",
    collections: "",
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const fileUploadHandler = useCallback(async () => {
    if (typeof formData?.file === "object") {
      const form = new FormData();
      form.append("file", formData.file);
      const apiResponse = await uploadSingleDocument(form);
      return apiResponse?.data?.data?.documentId;
    } else {
      return formData?.docId || "";
    }
  }, [formData]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      const docId = await fileUploadHandler();
      let cleanData = {
        name: formData?.name?.trim(),
        docId: docId || formData?.docId?.trim(),
        collectionIds: formData?.collections?.map((el) => el.value) || [],
      };

      let tempErrors = {};

      if (!cleanData.docId) {
        tempErrors["docId"] = "Image is required";
      }

      if (!cleanData.name) {
        tempErrors["name"] = "name is required";
      }

      if (cleanData.collectionIds?.length < 1) {
        tempErrors["collections"] = "Collection is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?._id) {
        await updateProductCategory(data?._id, cleanData);
      } else {
        await createProductCategory(cleanData);
      }
      setOpen(false);
      setFormData({
        docId: data?.document?._id || "",
        name: data?.name || "",
        image: data?.document?.url || "",
        file: "",
        collections:
          data?.collections?.map((el) => ({
            label: el.title,
            value: el._id,
          })) || [],
      });
      setErrors({
        docId: "",
        name: "",
        image: "",
        file: "",
        collections: [],
      });
      fetch();
    } catch (error) {
      console.log(error);
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?._id) {
      setFormData({
        docId: data?.document?._id || "",
        name: data?.name || "",
        image: data?.document?.url || "",
        file: "",
        collections:
          data?.collections?.map((el) => ({
            label: el.title,
            value: el._id,
          })) || [],
      });
    } else {
      setFormData({
        docId: "",
        name: "",
        image: "",
        file: "",
        collections: [],
      });
    }
  }, [data?._id]);

  const fileHandler = useCallback(
    (event) => {
       if (event?.target?.files[0]) {
        setFormData({
          ...formData,
          file: event.target.files[0],
          image: URL.createObjectURL(event.target.files[0]),
        });
      }
    },
    [formData]
  );

  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const fetchCollections = useCallback(async () => {
    try {
      const apiResponse = await getCollections();
      if (apiResponse?.data?.data?.collections?.length > 0) {
        setCollections([
          ...apiResponse.data.data.collections.map((el) => ({
            value: el._id,
            label: el.title,
          })),
        ]);
      } else {
        setCollections([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchCollections();
    }
  }, [open]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?._id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?._id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>
              {data?._id ? "Edit Product Category" : "Add Product Category"}
            </h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper-image">
                <div className="image">
                  {formData.image && <img src={formData.image} />}
                </div>
                <label htmlFor={type}>Upload Image (Aspect ratio - 3:2)</label>
                <input
                  onChange={fileHandler}
                  accept="Image/*"
                  id={type}
                  type="file"
                />
                {errors.docId && <p>{errors.docId}</p>}
              </div>
              <div className="input-wrapper">
                <label>Name</label>
                <input
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
                {errors.name && <p>{errors.name}</p>}
              </div>
            </div>
            <div className="input-wrapper">
              <label>Collections</label>
              <SelectField
                name="collections"
                onChange={selectDropdownChangeHandler}
                value={formData.collections}
                isMulti={true}
                options={collections}
              />
              {errors.collections && <p>{errors.collections}</p>}
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
