// import React, { useState, useEffect, useCallback } from "react";
// import { toast } from "react-toastify";
// import {
//   TextField,
//   Button,
//   MenuItem,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Box,
//   Container,
// } from "@mui/material";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import { createRole, getAllPermission } from "../../apis";

// const CreateRole = ({ stopLoadingRequest }) => {
//   const [roleName, setRoleName] = useState("");
//   const [permissions, setPermissions] = useState([]);
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [roles, setRoles] = useState([]);

//   // Fetch permissions from API
//   const fetchPermissions = useCallback(async () => {
//     try {
//       const response = await getAllPermission();
//       setPermissions(response.data.data);
//     } catch (error) {
//       console.error("Failed to fetch permissions:", error);
//     }
//   }, []);

//   useEffect(() => {
//     fetchPermissions();
//     stopLoadingRequest(); // Stop loading when component mounts
//   }, [fetchPermissions, stopLoadingRequest]);

//   const handleCreateRole = async () => {
//     const newRole = { name: roleName, permissions: selectedPermissions };
//     try {
//       const apiResponse = await createRole(newRole);
//       if (apiResponse?.data?.status === 200) {
//         console.log(apiResponse);
//         toast.success(apiResponse?.data?.message);
//         setRoles([...roles, newRole]);
//         setRoleName("");
//         setSelectedPermissions([]);
//       }
//     } catch (error) {
//       console.error("Failed to create role:", error);
//       toast.error(error.message);
//     }
//   };

//   return (
//     <Container
//       maxWidth="lg"
//       sx={{ mt: 4, marginLeft: "210px", marginTop: "40px" }}
//     >
//       <Box
//         sx={{
//           padding: "16px",

//           borderRadius: "8px",
//           boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
//           backgroundColor: "#ffffff",
//         }}
//       >
//         <Typography variant="h5" component="h1" gutterBottom>
//           Create Role
//         </Typography>
//         <Box display="flex" flexDirection="column" gap={2} mb={4}>
//           <TextField
//             label="Role Name"
//             variant="outlined"
//             fullWidth
//             value={roleName}
//             onChange={(e) => setRoleName(e.target.value)}
//             size="small"
//             sx={{ fontSize: "0.875rem" }} // Smaller text size
//           />
//           <Select
//             label="Select Permissions"
//             variant="outlined"
//             fullWidth
//             multiple
//             value={selectedPermissions}
//             onChange={(e) => setSelectedPermissions(e.target.value)}
//             renderValue={(selected) => selected.join(", ")}
//             size="small"
//             sx={{ fontSize: "0.875rem" }} // Smaller text size
//           >
//             {permissions.map((permission) => (
//               <MenuItem key={permission._id} value={permission._id}>
//                 {permission.name}
//               </MenuItem>
//             ))}
//           </Select>
//           <Button
//             variant="contained"
//             onClick={handleCreateRole}
//             sx={{
//               alignSelf: "flex-start",
//               color: "#fff",
//               backgroundColor: "#007bff",
//               fontSize: "0.75rem", // Smaller text size
//             }}
//           >
//             Create Role
//           </Button>
//         </Box>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell sx={{ fontSize: "0.875rem" }}>Role</TableCell>
//                 <TableCell sx={{ fontSize: "0.875rem" }}>Permissions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {roles.map((role, index) => (
//                 <TableRow key={index}>
//                   <TableCell sx={{ fontSize: "0.875rem" }}>
//                     {role.name}
//                   </TableCell>
//                   <TableCell sx={{ fontSize: "0.875rem" }}>
//                     {role.permissions.map((perm, idx) => (
//                       <span
//                         key={idx}
//                         style={{
//                           marginRight: "8px",
//                           backgroundColor: "#e0e0e0",
//                           padding: "4px 8px",
//                           borderRadius: "16px",
//                           display: "inline-block",
//                         }}
//                       >
//                         {perm}
//                       </span>
//                     ))}
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Container>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       stopLoadingRequest: actions.loader.stopLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(CreateRole);

import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Container,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { createUser, getAllRole } from "../../apis"; // Import the createRole function

const CreateUser = ({ stopLoadingRequest }) => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    password: "",
    roleId: "",
  });

  const [roles, setRoles] = useState([]);

  const fetchRoles = useCallback(() => {
    try {
      // const apiResponse = awiat getAllRole()
    } catch (error) {}
  }, []);

  const handleCreateUser = async () => {
    try {
      const apiResponse = await createUser(userDetails);
       toast.success("User Created");
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 4, marginLeft: "210px", marginTop: "40px" }}
    >
      <Box
        sx={{
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          Create User with Role
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} mb={4}>
          <TextField
            label="First Name"
            variant="outlined"
            name="firstName"
            value={userDetails.firstName}
            onChange={handleInputChange}
            size="small"
            sx={{ fontSize: "0.875rem" }} // Smaller text size
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            name="lastName"
            value={userDetails.lastName}
            onChange={handleInputChange}
            size="small"
            sx={{ fontSize: "0.875rem" }} // Smaller text size
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            name="email"
            value={userDetails.email}
            onChange={handleInputChange}
            size="small"
            sx={{ fontSize: "0.875rem" }} // Smaller text size
          />
          <Select
            label="Gender"
            variant="outlined"
            fullWidth
            name="gender"
            value={userDetails.gender}
            onChange={handleInputChange}
            size="small"
            sx={{ fontSize: "0.875rem" }} // Smaller text size
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            name="password"
            type="password"
            value={userDetails.password}
            onChange={handleInputChange}
            size="small"
            sx={{ fontSize: "0.875rem" }} // Smaller text size
          />
          <Select
            label="Role"
            variant="outlined"
            fullWidth
            name="roleId"
            value={userDetails.roleId}
            onChange={handleInputChange}
            size="small"
            sx={{ fontSize: "0.875rem" }} // Smaller text size
          >
            {roles.map((role) => (
              <MenuItem key={role._id} value={role._id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            onClick={handleCreateUser}
            sx={{
              alignSelf: "flex-start",
              color: "#fff",
              backgroundColor: "#007bff",
              fontSize: "0.75rem", // Smaller text size
            }}
          >
            Create User
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CreateUser);
