// import React, { useState, useEffect, useCallback } from "react";
// import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   getAllAdminUser,
//   activateDeactivateAdmin,
//   deactivateUser,
//   getAllCoachHistory,
//   updateCoach,
//   getCoaches, // Import the updateCoach API function
// } from "../../apis";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// import Pagination from "../../components/Pagination/Pagination";
// import AddEdit from "./AddEdit";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   TextField,
//   Select,
//   MenuItem,
// } from "@mui/material";

// const CoachHistory = ({
//   logoutRequest,
//   startLoadingRequest,
//   stopLoadingRequest,
// }) => {
//   const [data, setData] = useState([]);
//   const [page, setPage] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [total, setTotal] = useState(0);
//   const [totalUser, setTotalUser] = useState(null);
//   const [isModal, setIsModal] = useState(false);
//   const [coachName, setCoachName] = useState("");
//   const [selectedCoach, setSelectedCoach] = useState("");
//   const [selectedUserId, setSelectedUserId] = useState(""); // Store the selected user's ID for updating

//   const dummyCoaches = [
//     { coachId: "coachAId", coachName: "Coach A" },
//     { coachId: "coachBId", coachName: "Coach B" },
//     { coachId: "coachCId", coachName: "Coach C" },
//   ]; // Dummy coach data with coachId

//   const { pathname, search } = useLocation();
//   const rowsPerPage = 10;
//   const navigate = useNavigate();

//   const getAllCoachesDropDown = useCallback(async () => {
//     try {
//       const apiResponse = await getCoaches();
//       console.log(apiResponse);
//     } catch (error) {
//       console.log(error);
//     }
//   }, []);

//   const fetch = useCallback(async () => {
//     try {
//       const apiResponse = await getAllCoachHistory();
//       console.log(apiResponse);
//       if (apiResponse?.data?.data) {
//         setData(apiResponse.data.data); // Set the user data from the response
//         setTotalUser(apiResponse.data.total); // Assuming total users info
//         setCurrentPage(apiResponse.data.currentPage); // Assuming pagination info
//       } else {
//         setData([]);
//       }
//       stopLoadingRequest();
//     } catch (error) {
//       if (error?.response?.status === 401) {
//         toast.error(error.response.data.message);
//         logoutRequest();
//       } else if (error?.response?.status === 403) {
//         toast.error(error.response.data.message);
//       }
//       stopLoadingRequest();
//     }
//   }, [pathname, search, stopLoadingRequest, logoutRequest, page]);

//   useEffect(() => {
//     stopLoadingRequest();
//     getAllCoachesDropDown();
//   }, []);

//   useEffect(() => {
//     fetch();
//   }, []);

//   useEffect(() => {
//     startLoadingRequest();
//     fetch(page, rowsPerPage);
//   }, [pathname, search, startLoadingRequest, fetch, page]);

//   const handleUpdateCoach = (userId) => {
//     setSelectedUserId(userId); // Set the userId for the selected user
//     setIsModal(true);
//   };

//   const handleCloseModal = () => {
//     setIsModal(false);
//     setCoachName(""); // Reset coach name when modal closes
//     setSelectedCoach(""); // Reset selected coach when modal closes
//   };

//   const handleSubmitModal = async () => {
//     const payload = {
//       coachId: selectedCoach, // Selected coachId from the dropdown
//       userId: selectedUserId, // UserId of the user to be updated
//     };

//     try {
//       await updateCoach(payload); // Call the updateCoach API with the payload
//       toast.success("Coach updated successfully!");
//       fetch(); // Refresh data after the update
//       handleCloseModal(); // Close modal after submission
//     } catch (error) {
//       console.error("Error updating coach:", error);
//       toast.error("Failed to update coach");
//     }
//   };

//   const handlePageChange = (newPage) => {
//     navigate(`/admins?page=${newPage}`);
//     setPage(newPage);
//   };

//   return (
//     <div className="section">
//       <h1>Coach History</h1>
//       <div className="wrapper">
//         <div className="table-wrapper">
//           <div className="table-header">
//             <AddEdit fetch={fetch} />
//           </div>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>#</th>
//                   <th>User</th>
//                   <th>Coach History</th>
//                   <th>Current Coach</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {data?.length > 0 ? (
//                   data.map((item, index) => (
//                     <tr key={item.userId}>
//                       <td>{Number((currentPage - 1) * 10 + index + 1)}</td>
//                       <td>{item.userName}</td> {/* Display user name */}
//                       <td>
//                         {/* Coach History Dropdown */}
//                         <select>
//                           {item.assignedCoaches.map((coach, idx) => (
//                             <option key={idx} value={coach.coachId}>
//                               {coach.coachName}
//                             </option>
//                           ))}
//                         </select>
//                       </td>
//                       <td>
//                         {/* Displaying current coach */}
//                         {item.currentlyAssignedCoaches.length > 0
//                           ? item.currentlyAssignedCoaches[0].coachName
//                           : "No Coach Assigned"}
//                       </td>
//                       <td>
//                         <div className="table-actions">
//                           <button
//                             onClick={() => handleUpdateCoach(item.userId)}
//                             className="success"
//                           >
//                             Update Coach
//                           </button>
//                         </div>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="5" align="center">
//                       No users found.
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//           {data.length > 0 && (
//             <Pagination
//               pageInfo={{
//                 page: currentPage,
//                 total: total,
//                 totaluser: totalUser,
//                 rowsPerPage: rowsPerPage,
//               }}
//               onPageChange={handlePageChange}
//             />
//           )}
//         </div>
//       </div>

//       {/* Modal for updating coach */}
//       <Dialog open={isModal} onClose={handleCloseModal}>
//         <DialogTitle>Update Coach</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Coach Name"
//             fullWidth
//             value={coachName}
//             onChange={(e) => setCoachName(e.target.value)} // Update coach name state
//             margin="dense"
//           />
//           <Select
//             label="Select Coach"
//             fullWidth
//             value={selectedCoach}
//             onChange={(e) => setSelectedCoach(e.target.value)} // Update selected coach state
//             margin="dense"
//           >
//             {dummyCoaches.map((coach, index) => (
//               <MenuItem key={index} value={coach.coachId}>
//                 {coach.coachName}
//               </MenuItem>
//             ))}
//           </Select>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseModal}>Cancel</Button>
//           <Button onClick={handleSubmitModal} color="primary">
//             Submit
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       stopLoadingRequest: actions.loader.stopLoading,
//       startLoadingRequest: actions.loader.startLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(CoachHistory);

import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllAdminUser,
  activateDeactivateAdmin,
  deactivateUser,
  getAllCoachHistory,
  updateCoach,
  getCoaches, // Import the getCoaches API
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import AddEdit from "./AddEdit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
} from "@mui/material";

const CoachHistory = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [data, setData] = useState([]);
  const [coaches, setCoaches] = useState([]); // To store all coaches for dropdown
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalUser, setTotalUser] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(""); // Store the selected user's ID for updating

  const { pathname, search } = useLocation();
  const rowsPerPage = 10;
  const navigate = useNavigate();

  // Fetch user data (Coach History)
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAllCoachHistory();
      if (apiResponse?.data?.data) {
        setData(apiResponse.data.data);
        setTotalUser(apiResponse.data.total);
        setCurrentPage(apiResponse.data.currentPage);
      } else {
        setData([]);
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [pathname, search, stopLoadingRequest, logoutRequest, page]);

  // Fetch all coaches for the dropdown
  const fetchCoaches = useCallback(async () => {
    try {
      const response = await getCoaches(); // Fetch the coaches
       setCoaches(response.data?.data[0].allUsers || []); // Assuming the coaches are in `allUsers` array
    } catch (error) {
      console.error("Error fetching coaches:", error);
    }
  }, []);

  useEffect(() => {
    fetch(page, rowsPerPage);
    fetchCoaches(); // Fetch coaches when component loads
  }, [page, rowsPerPage, fetch, fetchCoaches]);

  useEffect(() => {
    startLoadingRequest();
    fetch(page, rowsPerPage);
  }, [pathname, search, startLoadingRequest, fetch, page]);

  const handleUpdateCoach = (userId) => {
    setSelectedUserId(userId); // Set the userId for the selected user
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
    setSelectedCoach(""); // Reset selected coach when modal closes
  };

  const handleSubmitModal = async () => {
    const payload = {
      coachId: selectedCoach, // Selected coachId from the dropdown
      userId: selectedUserId, // UserId of the user to be updated
    };

    try {
      await updateCoach(payload); // Call the updateCoach API with the payload
      toast.success("Coach updated successfully!");
      fetch(); // Refresh data after the update
      handleCloseModal(); // Close modal after submission
    } catch (error) {
      console.error("Error updating coach:", error);
      toast.error("Failed to update coach");
    }
  };

  const handlePageChange = (newPage) => {
    navigate(`/admins?page=${newPage}`);
    setPage(newPage);
  };

  return (
    <div className="section">
      <h1>Coach History</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">{/* <AddEdit fetch={fetch} /> */}</div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Coach History</th>
                  <th>Current Coach</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={item.userId}>
                      {/* <td>{Number((currentPage - 1) * 10 + index + 1)}</td> */}
                      <td>{item.userName}</td> {/* Display user name */}
                      <td>
                        {/* Coach History Dropdown */}
                        <select>
                          {item.assignedCoaches.map((coach, idx) => (
                            <option key={idx} value={coach.coachId}>
                              {coach.coachName}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        {/* Displaying current coach */}
                        {item.currentlyAssignedCoaches.length > 0
                          ? item.currentlyAssignedCoaches[0].coachName
                          : "No Coach Assigned"}
                      </td>
                      <td>
                        <div className="table-actions">
                          <button
                            onClick={() => handleUpdateCoach(item.userId)}
                            className="success"
                          >
                            Update Coach
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" align="center">
                      No users found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* {data.length > 0 && (
            <Pagination
              pageInfo={{
                page: currentPage,
                total: total,
                totaluser: totalUser,
                rowsPerPage: rowsPerPage,
              }}
              onPageChange={handlePageChange}
            />
          )} */}
        </div>
      </div>

      {/* Modal for updating coach */}
      <Dialog open={isModal} onClose={handleCloseModal}>
        <DialogTitle>Update Coach</DialogTitle>
        <DialogContent>
          <Select
            label="Select Coach"
            fullWidth
            value={selectedCoach}
            onChange={(e) => setSelectedCoach(e.target.value)} // Update selected coach state
            margin="dense"
          >
            {coaches.map((coach) => (
              <MenuItem key={coach._id} value={coach._id}>
                {coach.firstName} {coach.lastName}{" "}
                {/* Display coach full name */}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSubmitModal} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CoachHistory);
