import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaRegUser } from "react-icons/fa/index.esm";
import { FaKey } from "react-icons/fa/index.esm";
import { FaEye } from "react-icons/fa/index.esm";
import { login } from "../../apis";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import routes from "../../components/Sidebar/routes"; // Import the routes file

const Login = ({
  loginRequest,
  stopLoadingRequest,
  startLoadingRequest,
  isAuthenticated,
  userDetailsRequest,
  dataRequest,
}) => {
  const navigate = useNavigate();

  const initialState = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authorization")) {
      navigate("/dashboard?requestType=Today");
    } else {
      stopLoadingRequest();
    }
  }, []);

  const changeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    startLoadingRequest();
    try {
      let apiResponse = await login(formData);
 
      if (apiResponse?.data?.accessToken) {
        localStorage.setItem("authorization", apiResponse.data.accessToken);
        localStorage.setItem(
          "userDetails",
          JSON.stringify(apiResponse?.data?.user) // Convert user object to string
        );
         loginRequest(apiResponse?.data?.user);
        // userDetailsRequest(apiResponse?.data?.user);
        // dataRequest(apiResponse?.data?.user);
        stopLoadingRequest();
        const userPermissions =
          JSON.parse(localStorage.getItem("userDetails"))?.permissions || [];
        const permittedRoute = routes.find((route) =>
          userPermissions.some((perm) => perm.name === route.permission)
        );

        navigate(permittedRoute.path);
      }
      setFormData(initialState);
    } catch (error) {
      stopLoadingRequest();
      toast.error(error.response?.data.message);
    }
  };

  return (
    <div className="login-container">
      <form className="login-wrapper">
        <div className="header">
          <div className="logo">
            <img src="/images/Ponderiee.svg" />
          </div>
        </div>
        <div className="content">
          <div className="input-wrapper">
            <label>Email</label>
            <div className="icon-wrapper">
              <div className="icon">
                <FaRegUser size={14} />
              </div>
              <input
                name="email"
                onChange={changeHandler}
                value={formData.email}
                placeholder="email"
              />
            </div>
          </div>
          <div className="input-wrapper">
            <label>Password</label>
            <div className="icon-wrapper">
              <div className="icon">
                <FaKey size={13} />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={changeHandler}
                value={formData.password}
                placeholder="password"
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                className={showPassword ? "icon active" : "icon"}
              >
                <FaEye size={16} />
              </div>
            </div>
          </div>
        </div>
        <div className="forgot-password-link">
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
        <div className="footer">
          <button onClick={submitHandler} type="button" className="primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {   userDetailsRequest: actions.auth.userDetails,
      dataRequest:actions.auth.data,
      loginRequest: actions.auth.login,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,

    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Login);
