import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../actions";
import styles from "./Chat.module.css"; // Assuming you create a CSS module file for styling
import { io } from "socket.io-client";
import { getAllCoach, getUserById } from "../../apis";
import { SetMealSharp } from "@mui/icons-material";
import OutiftEditor from "../../components/OutfitEditor/OutfitEditor";
import TestCompo from "../../components/OutfitEditor/TestCompo";

const Chat = ({ startLoadingRequest, stopLoadingRequest }) => {
  const [users, setUsers] = useState([]);
  const [pendingMessageCount, setPendingMessageCount] = useState(0)
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [media, setMedia] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [chats, setChats] = useState([]);
  const [userid, setUserid] = useState(null);
  const [wordrobItem, setWordrobItem] = useState([])
  const [outfitItem, setOutfitItem] = useState([])
  const [createOutfit, setCreateOutfit] = useState(false)
  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);


  useEffect(() => {
    stopLoadingRequest();

    // Fetch user details from localStorage once and set userid
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      const user_id = JSON.parse(userDetails)._id;
      setUserid(user_id);
    }
  }, []);

  const handleSendMessage = () => {
    // console.log("hit send", selectUser);
    // console.log(message);
    const userDetails = localStorage.getItem("userDetails");

    if (userDetails) {
      // console.log(userDetails, "11111");
      const user_id = JSON.parse(userDetails)._id;
      const senderId = user_id;
      const receiverId = selectUser.userId._id;
      const roomId = selectUser.roomId;

      // if (socket && selectedUser) {
      socket.emit("send_message", message, senderId, receiverId, roomId, media);
      setMessage("");

      // }
    }
  };

  const loadChat = (user) => {
    const userDetails = localStorage.getItem("userDetails");
    const user_id = JSON.parse(userDetails);
    // setUserid(user_id._id);
    // console.log(user);
    const senderId = user_id?._id;
    const receiverId = user?.userId?._id;
    if (socket) {
      socket.emit("joinAdminRoom", senderId, receiverId);
      socket.emit("loadAdminChat", senderId, receiverId);
    }
  };

  // useEffect(() => {
  //   const userDetails = localStorage.getItem("userDetails");
  //   const user_id = JSON.parse(userDetails);
  //   const fetchUser = async () => {
  //     const response = await getAllCoach(user_id._id);
  //     console.log(response);
  //     setUsers(response?.data?.data);
  //     console.log(response);
  //   };
  //   fetchUser();
  // }, []);

  useEffect(() => {
    const newSocket = io("wss://dev-api.ponderiee.com", {
      extraHeaders: { "ngrok-skip-browser-warning": "any-value" },
    });

    newSocket.on("connect", () => {
      setSocket(newSocket);
    });

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  const loadUserData = (item) => {
     setUsers(item);
    let data = item.filter((user) => user?.seenCount > 0 && user?.isChatOpen == true).length
    setPendingMessageCount(data)


  }

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    const user_id = JSON.parse(userDetails);
    // console.log(user_id?._id, "2222");

    if (!socket) return;
    // socket.emit("loadAdminUsers", user_id?._id);

    // const handleLoadAdminUsers = (item) => {
    //   console.log(item);
    //   //   setUsers(item);
    // };


    const handleAdminMessageReceived = (item) => {
      // console.log(item);
      setMessages((prevMessages) => [...prevMessages, item]);
    };

    const handleLoadAdminChat = (item) => {
      // console.log(item);
      setMessages(item);
    };

    socket.emit("loadUsers", user_id?._id)
    socket.on("message_received", handleAdminMessageReceived);
    socket.on("loadAdminChat", handleLoadAdminChat);
    socket.on("loadUsers", loadUserData)

    return () => {
      socket.off("message_received", handleAdminMessageReceived);
      socket.off("loadAdminChat", handleLoadAdminChat);
    };
  }, [socket]);

  const renderOptions = (options) => {
    return (
      <div className={styles.optionsContainer}>
        {options.map((option, index) => (
          <button key={index} className={styles.optionButton}>
            {option}
          </button>
        ))}
      </div>
    );
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.dropdownContainer')) {
      setDropdownVisible(false);
    }
  };
  // console.log(dropdownVisible,"dropdownVisible")
  // To close dropdown on click outside
  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  async function selectingUser(user) {
    setSelectUser(user);
    // let userData = await 
    // let data = await getUserById(user?.userId?._id)
    // let userData = data?.data?.data
    // setWordrobItem(userData.waredrob)
    // setOutfitItem(userData?.outfits)
    loadChat(user);
    socket.emit("isSeen", user?.userId?._id)
    const userDetails = localStorage.getItem("userDetails");
    const user_id = JSON.parse(userDetails);


    socket.emit("loadUsers", user_id?._id)

    socket.on("loadUsers", loadUserData)
  }

  const openModal = (type) => {
    setIsModalOpen(true);
    if (type === "wardrobe") {
      setModalContent(wordrobItem);
    } else if (type === "outfits") {
      setModalContent(outfitItem);
    }
    toggleDropdown()
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent([]);
  };



  function closeChat(user) {
    // console.log(user,"11111")
    const userDetails = localStorage.getItem("userDetails");
    const user_id = JSON.parse(userDetails);

    socket.emit("closeChat", selectUser.userId?._id, user_id?._id)
  }

  return (
    <>

      <div className="section chatContainer" style={{ height: "90%", padding: "0", display: "block", overflow: "hidden" }}>
        {/* <div className="section chatContainer"> */}

        <div style={{ display: "flex", height: "90vh" }}>

          <div className={styles.userList}>
            {/* <div className={styles.userList} style={{border:"1px solid red"}}> */}
            {/* Placeholder for users - replace with actual user data */}
            <div className="open_chats" style={{ height: "50%", overflow: "hidden", marginBottom: "1rem" }}>
              <h3 style={{ display: "flex", justifyContent: "space-between" }}><span>Open chats</span>{pendingMessageCount ? (<span style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "grey",
                color: 'white',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                fontSize: '12px',
                marginLeft: '8px',
                fontWeight: 'bold',
              }}>
                {pendingMessageCount}

              </span>) : ""}</h3>
              <hr />
              <div className="open_chats" style={{ height: "100%", overflowY: "scroll" }}>
                {users?.map((user, index) => user?.isChatOpen && (
                  <div
                    key={index}
                    className={styles.userItem}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => {
                      // setSelectUser(user?.userId);
                      selectingUser(user)

                    }}
                  >
                    <span> {user?.userId?.firstName || "no name"}</span>
                    {user?.seenCount ? <span style={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#25D366',
                      color: 'white',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      fontSize: '12px',
                      marginLeft: '8px',
                      fontWeight: 'bold',
                    }}>
                      {user?.seenCount}
                    </span> : ""}
                  </div>
                ))}

              </div>
            </div>
            <div className="closed_chat" style={{ height: "50%", overflow: "hidden", marginBottom: "1rem" }}>
              <h3>Close chats</h3>
              <hr style={{ marginBottom: "20px" }} />
              <div className="closed_chat" style={{ height: "100%", overflowY: "scroll" }}>
                {users?.map((user, index) => !user?.isChatOpen && (
                  <div
                    key={index}
                    className={styles.userItem}
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => {
                      // setSelectUser(user?.userId);
                      selectingUser(user)

                    }}
                  >
                    <span> {user?.userId?.firstName || "no name"}</span>


                  </div>
                ))}

              </div>
            </div>

          </div>
          <div className={styles.chatWindow} style={{ border: "1px solid green", margin: "0", padding: "0" }}>
            {selectUser ? (
              <>
                <div className={styles.chatHeader} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem" }}>
                  <span>Chat with {selectUser.firstName} </span>


                  <div className={styles.dropdownContainer}>
                    <div className={styles.actionButton} onClick={toggleDropdown}>
                      &#x22EE; {/* Unicode for triple dots (vertical ellipsis) */}

                      {dropdownVisible && (

                        !selectUser ? <span className={styles.dropdownMenu} style={{ padding: "1rem", marginTop: "1rem" }} >Please select the user first</span> :
                          <div className={styles.dropdownMenu}>

                            {/* <button
                              className={styles.dropdownItem}
                              onClick={() => openModal("wardrobe")}
                            >
                              View Wardrobe
                            </button>
                            <button
                              className={styles.dropdownItem}
                              onClick={() => openModal("outfits")}
                            >
                              View Outfits
                            </button> */}
                            <button className={styles.dropdownItem}
                              onClick={() => setCreateOutfit(!createOutfit)}>
                                Create Outfit
                            </button>
                            <button
                              className={styles.dropdownItem}
                              onClick={() => closeChat(selectUser)}
                            >
                              Close chat
                            </button>

                          </div>

                      )}
                    </div>





                  </div>
                </div>
                <div className={styles.chatMessages}>
                  {messages.map((chat) => (
                    <div key={chat._id}>
                      {
                        <div
                          className={
                            chat.senderId === userid
                              ? styles.adminMessage
                              : styles.userMessage
                          }
                        >
                          {chat.message}
                          {chat.senderId === userid &&
                            chat.options &&
                            chat.options.length > 0 &&
                            renderOptions(chat.options)}
                            <div >
                            {chat.media?.map((item)=><div ><img style={{width:"100%",height:"100%"}} src={item}/></div>)}
                            {<div ><img style={{width:"100%",height:"100%"}} src={chat?.adminOutfit?.outfitItem}/></div>}
                            </div>
                        </div>
                      }
                    </div>
                  ))}
                </div>
                {selectUser?.isChatOpen && <div className={styles.chatInput}>
                  {/* Chat input box and send button */}
                  <input
                    onChange={(e) => {
                      setMessage(e.target.value); // Update the message state on change
                    }}
                    value={message} // Bind the input value to the message state
                    type="text"
                    placeholder="Type a message..."
                  />
                  {/* <button onClick={closeTheChat}>Close chat</button> */}
                  <button onClick={handleSendMessage}>Send</button>
                </div>}
              </>
            ) : (
              <div className={styles.noChatSelected}>
                Select a user to start chatting
              </div>
            )}
          </div>
        </div>
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modal}>
              <button className={styles.closeButton} onClick={closeModal}>
                &times;
              </button>
              <div className={styles.modalContent}>
                {modalContent.length > 0 ? (
                  modalContent.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl?.image}
                      alt={`Item ${index}`}
                      className={styles.modalImage}
                    />
                  ))
                ) : (
                  <p>No items to display</p>
                )}
              </div>
            </div>
          </div>
        )}
        {createOutfit&&
          <div className={styles.modalOverlay}>
            {<OutiftEditor userId = {selectUser.userId._id} socket={socket} createOutfit={createOutfit} setCreateOutfit={setCreateOutfit} roomId={selectUser.roomId}/>}
            {/* {<TestCompo userId = {selectUser.userId._id} socket={socket} createOutfit={createOutfit} setCreateOutfit={setCreateOutfit} roomId={selectUser.roomId}/>} */}
          </div>
        }
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Chat);
