import { FaUsers, FaQuestion } from "react-icons/fa/index.esm";
import { PiFlagBannerFill } from "react-icons/pi/index.esm";
import { FaCalendarAlt } from "react-icons/fa/index.esm";
import {
  MdPrivacyTip,
  MdTipsAndUpdates,
  MdAnalytics,
} from "react-icons/md/index.esm";
import { TbInfoTriangleFilled } from "react-icons/tb/index.esm";
import { RiCoupon3Fill, RiAdminFill } from "react-icons/ri/index.esm";
import { GiShoppingBag, GiShop, GiSaloon } from "react-icons/gi/index.esm";
import { IoNewspaperSharp } from "react-icons/io5/index.esm";
import { TbCategoryFilled } from "react-icons/tb/index.esm";
import Login from "../../pages/Auth/Login";
import ForgotPassword from "../../pages/Auth/ForgotPassword";
import ResetPassword from "../../pages/Auth/ResetPassword";
import UserDetails from "../../pages/UserDetails";
import Admins from "../../pages/Admins";
import AdminCategory from "../../pages/AdminCategory";
import Role from "../../pages/Role";
import CoachHistory from "../../pages/CoachHistory";
import Banners from "../../pages/Banners";
import Appointments from "../../pages/Appointments/Appointments";
import PrivacyPolicies from "../../pages/PrivacyPolicies";
import TermsAndConditions from "../../pages/TermsAndConditions";
import TipsAndTricks from "../../pages/TipsAndTricks";
import BlogCategories from "../../pages/BlogCategories";
import Blogs from "../../pages/Blogs";
import Orders from "../../pages/Orders";
import Users from "../../pages/Users";
import CreateUser from "../../pages/CreateUser";
import CreateRole from "../../pages/CreateRole";
import ProductPromocodes from "../../pages/ProductPromocodes";
import Chat from "../../pages/Chat";
import AccountSettings from "../../pages/AccountSettings";
import ChangePassword from "../../pages/ChangePassword";
import ProductCategories from "../../pages/ProductCategories";
import NotificationPage from "../../pages/Notification";
import Dashboard from "../../pages/Dashboard";
import Images from "../../pages/Salons";
import Faqs from "../../pages/Faqs";
import { Children } from "react";
import { icons } from "react-icons";
import { components } from "react-select";
import HelpCenter from "../../pages/HelpCenter";
import { MdOutlineHelpCenter } from "react-icons/md";
import HumanCoach from "../../pages/HumanCoach";
import CategorySubcategory from "../../pages/CategorySubcategory";
import { BiCategory } from "react-icons/bi";

export default [
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
    isAuthenticated: false,
  },
  {
    path: "/login",
    component: <Login />,
    isAuthenticated: false,
  },
  {
    path: "/resetpasswords",
    component: <ResetPassword />,
    isAuthenticated: false,
  },
  {
    path: "/settings",
    component: <AccountSettings />,
    isAuthenticated: true,
  },
  {
    path: "/password",
    component: <ChangePassword />,
    isAuthenticated: true,
  },
  {
    link: "/dashboard?requestType=Today",
    icon: <MdAnalytics size={18} />,
    path: "/dashboard",
    component: <Dashboard />,
    isAuthenticated: true,
    label: "Dashboard",
    permission: "dashboard",
  },
  {
    link: "/users?page=1",
    icon: <FaUsers size={18} />,
    path: "/users",
    component: <Users />,
    isAuthenticated: true,
    label: "User Management",
    permission: "user_management",
  },

  ,
  // {
  //   link: "/Categories",
  //   icon: <FaUsers size={18} />,
  //   path: "/Categories",
  //   component: <Categories/>,
  //   isAuthenticated: true,
  //   label: "Category",
  //   permission: "Category",

  // },

  // {
  //   link: "/Subcategories",
  //   icon: <FaUsers size={18} />,
  //   path: "/Subcategories",
  //   component: <Subcategories/>,
  //   isAuthenticated: true,
  //   label: "Subcategory",
  //   permission: "Subcategory",

  // },
  // {
  //   link: "/salons/images",
  //   icon: <GiSaloon size={18} />,
  //   path: "/salons/images",
  //   component: <Images />,
  //   isAuthenticated: true,
  //   label: "Salons",
  // },
  {
    path: "/users/:id",
    component: <UserDetails />,
    isAuthenticated: true,
    label: "userdetails",
    permission: "user details",
  },
  {
    link: "/admincategory",
    icon: <FaUsers size={18} />,
    path: "/AdminCategory",
    component: <AdminCategory />,
    isAuthenticated: true,
    label: "Admin Category",
    permission: "Admin Category",
  },
  // {
  //   link: "/appointments?page=1&requestType=Completed&search=",
  //   icon: <FaCalendarAlt size={14} />,
  //   path: "/appointments",
  //   component: <Appointments />,
  //   isAuthenticated: true,
  //   label: "Booking List",
  //   permission: "booking_list",
  // },
  // {
  //   link: "/orders?page=1&requestType=All&search=",
  //   icon: <GiShoppingBag size={14} />,
  //   path: "/orders",
  //   component: <Orders />,
  //   isAuthenticated: true,
  //   label: "Order List",
  //   permission: "order_list",
  // },
  // {
  //   link: "/product-promocodes",
  //   icon: <RiCoupon3Fill size={14} />,
  //   path: "/product-promocodes",
  //   component: <ProductPromocodes />,
  //   isAuthenticated: true,
  //   label: "Product Promocodes",
  //   permission: "product_promocodes",
  // },
  {
    link: "/admins?page=1",
    icon: <RiAdminFill size={18} />,
    path: "/admins",
    component: <Admins />,
    isAuthenticated: true,
    label: "Admins",
    permission: "admins",
  },
  // {
  //   link: "/blog-categories",
  //   icon: <TbCategoryFilled size={14} />,
  //   path: "/blog-categories",
  //   component: <BlogCategories />,
  //   isAuthenticated: true,
  //   label: "Blog Categories",
  //   permission: "blogs",
  // },
  // {
  //   link: "/blogs?page=1",
  //   icon: <IoNewspaperSharp size={14} />,
  //   path: "/blogs",
  //   component: <Blogs />,
  //   isAuthenticated: true,
  //   label: "Blogs",
  //   permission: "blogs",
  // },
  {
    link: "/product-categories",
    icon: <GiShop size={14} />,
    path: "/product-categories",
    component: <ProductCategories />,
    isAuthenticated: true,
    label: "Product Categories",
    permission: "product_categories",
  },
  // {
  //   link: "/tips-tricks?page=1",
  //   icon: <MdTipsAndUpdates size={14} />,
  //   path: "/tips-tricks",
  //   component: <TipsAndTricks />,
  //   isAuthenticated: true,
  //   label: "Tips And Tricks",
  //   permission: "tips_tricks",
  // },
  // {
  //   link: "/banners?page=1",
  //   icon: <PiFlagBannerFill size={18} />,
  //   path: "/banners",
  //   component: <Banners />,
  //   isAuthenticated: true,
  //   label: "Banners",
  //   permission: "banners",
  // },
  {
    link: "/privacy-policies?page=1",
    icon: <MdPrivacyTip size={14} />,
    path: "/privacy-policies",
    component: <PrivacyPolicies />,
    isAuthenticated: true,
    label: "Privacy Policies",
    permission: "privacy_policies",
  },
  // {
  //   link: "/terms-conditions?page=1",
  //   icon: <TbInfoTriangleFilled size={14} />,
  //   path: "/terms-conditions",
  //   component: <TermsAndConditions />,
  //   isAuthenticated: true,
  //   label: "Terms and Conditions",
  //   permission: "terms_conditions",
  // },
  // {
  //   link: "/faqs",
  //   icon: <FaQuestion size={14} />,
  //   path: "/faqs",
  //   component: <Faqs />,
  //   isAuthenticated: true,
  //   label: "Faqs",
  //   permission: "faqs",
  // },
  {
    link: "/createrole",
    icon: <FaQuestion size={14} />,
    path: "/createrole",
    component: <CreateRole />,
    isAuthenticated: true,
    label: "Create Role",
    permission: "role",
  },

  ,
  // {
  //   link: "/createuser",
  //   icon: <FaQuestion size={14} />,
  //   path: "/createuser",
  //   component: <CreateUser />,
  //   isAuthenticated: true,
  //   label: "Create User",
  //   permission: "role",
  // },
  {
    link: "/chat",
    icon: <MdOutlineHelpCenter size={20} />,
    path: "/chat",
    component: <HelpCenter />,
    isAuthenticated: true,
    label: "Help Center",
    permission: "role",
  },
  // {
  //   link: "/createuser",
  //   icon: <FaQuestion size={14} />,
  //   path: "/createuser",
  //   component: <CreateUser />,
  //   isAuthenticated: true,
  //   label: "Create User",
  //   permission: "role",
  // },
  {
    link: "/notification",
    icon: <MdOutlineHelpCenter size={20} />,
    path: "/notification",
    component: <NotificationPage />,
    isAuthenticated: true,
    label: "Notification",
    permission: "role",
  },
  {
    link: "/category-subcategory",
    icon: <BiCategory size={20} />,
    path: "/category-subcategory",
    component: <CategorySubcategory />,
    isAuthenticated: true,
    label: "Category/Subcategory",
    permission: "role",
  },
  {
    link: "/human-coach",
    icon: <MdOutlineHelpCenter size={20} />,
    path: "/human-coach",
    component: <Chat />,
    isAuthenticated: true,
    label: "Human Coach",
    permission: "chat_with_coach",
  },
  {
    link: "/coach-history",
    icon: <MdOutlineHelpCenter size={20} />,
    path: "/coach-history",
    component: <CoachHistory />,
    isAuthenticated: true,
    label: "Coach History",
    permission: "coach_history",
  },
];
