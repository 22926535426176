import { combineReducers } from "redux";
import auth from "./auth";
import loader from "./loader";
import sidebar from "./sidebar";

export default combineReducers({
  auth,
  loader,
  sidebar,
});
