import React, { useState, useEffect, useCallback } from "react";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";
import "./admincategory.css";
import {
  getAllCategorySubcategory, // Assuming this fetches the full dataset
  createCategory,
  updateCategory,
  deleteAdminCategory,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { TablePagination } from "@mui/material";

const AdminCategory = ({
  stopLoadingRequest,
  startLoadingRequest,
  activateModal,
  deactivateModal,
}) => {
  const [categories, setCategories] = useState([]); // Full dataset
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [stylePreference, setStylePreference] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState("");
  const [rowsPerPage,setRowsPerPage] = useState(10); // How many rows to show per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(0);

  // Fetch all categories at once
  const fetch = useCallback(async (page, rowsPerPage) => {
    startLoadingRequest();
    try {
       const apiResponse = await getAllCategorySubcategory("category",page + 1, rowsPerPage);
      if (apiResponse?.data?.data) {
        setCategories(apiResponse?.data?.data?.categories); // Store full dataset in state
        setTotalUsers(apiResponse?.data?.data?.totalUsers || 0);

        
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      stopLoadingRequest();
    }
  }, [startLoadingRequest, stopLoadingRequest]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    fetch(newPage, rowsPerPage); // Fetch users for the new page
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
    fetch(0, newRowsPerPage); // Fetch users for the new rows per page
  };

  useEffect(() => {
    fetch(page, rowsPerPage); // Fetch data on component mount
  }, [fetch]);

  // Handle page change by setting the current page directly
  const handlePageChange = (page) => {
    setCurrentPage((prev) => prev + 1); // Set the current page to the value passed by the Pagination component
  };

  // Calculate the indexes for slicing the categories for the current page
  const indexOfLastCategory = currentPage * rowsPerPage;
  const indexOfFirstCategory = indexOfLastCategory - rowsPerPage;
  // const currentCategories = categories?categories.slice(
  //   indexOfFirstCategory,
  //   indexOfLastCategory
  // );

  // Handle creating or updating a category
  const handleSubmitCategory = async (e) => {
    e.preventDefault();

    if (!categoryName || !stylePreference) {
      setError("All fields (category name and style preference) are required");
      return;
    }

    try {
      setError("");
      const formData = {
        name: categoryName,
        stylePreference,
      };

      if (selectedCategory) {
        formData.categoryId = selectedCategory._id;
        await updateCategory(formData);
        toast.success("Category updated successfully!");
      } else {
        await createCategory(formData);
        toast.success("Category created successfully!");
      }

      setIsModalOpen(false);
      fetch(); // Refresh categories list
      resetForm();
    } catch (error) {
      console.error("Error creating/updating category:", error);
      toast.error("Failed to process the category.");
    }
  };

  // Reset the form after submission or cancel
  const resetForm = () => {
    setCategoryName("");
    setStylePreference("");
    setSelectedCategory(null);
  };

  const handleAddCategory = (category = null) => {
    setIsModalOpen(true);

    if (category) {
      setSelectedCategory(category);
      setCategoryName(category.name);
      setStylePreference(category.stylePreference);
    } else {
      resetForm();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
    setError("");
  };

  const handleDeleteCategory = async (data) => {
     if (data) {
      const response = await deleteAdminCategory({ categoryId: data?._id });
       fetch();
    }
  };

  return (
    <div className="">
      <button
        className="add-category-button-new"
        onClick={() => handleAddCategory(null)}
      >
        Add Category  
      </button>
      <table className="category-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Category Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories?.map((category, index) => (
            <tr key={category._id}>
              <td>{indexOfFirstCategory + index + 1}</td>{" "}
              {/* Adjust the index */}
              <td>{category?.name}</td>
              <td>
                <div className="table-actions">
                  <button
                    onClick={() => handleAddCategory(category)}
                    className="edit-button-new"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteCategory(category);
                    }}
                    className="delete-button-new"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

      {isModalOpen && (
        <div
          style={{
            zIndex: "10000",
            position: "fixed", // Changed to fixed
            top: 0, // Covering the entire screen
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent background
            backdropFilter: "blur(10px)", // Blur effect
          }}
        >
          <div className="modal-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ margin: 0 }}>
                {selectedCategory ? "Edit Category" : "Add New Category"}
              </h2>
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            </div>
            <form onSubmit={handleSubmitCategory}>
              <div className="form-group">
                <label htmlFor="categoryName">Category Name</label>
                <input
                  type="text"
                  id="categoryName"
                  name="categoryName"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="stylePreference">Style Preference</label>
                <select
                  id="stylePreference"
                  name="stylePreference"
                  value={stylePreference}
                  onChange={(e) => setStylePreference(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Style Preference
                  </option>
                  <option value="Men">Men</option>
                  <option value="Women">Women</option>
                </select>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <div className="modal-actions">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button type="submit" className="submit-button">
                  {selectedCategory ? "Update" : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* {categories.length > 0 && (
        <Pagination
          pageInfo={{
            page: currentPage,
            total: currentCategories?.length,
            rowsPerPage: rowsPerPage,
          }}
          onPageChange={handlePageChange}
        />
      )} */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      activateModal: actions.modal.activateModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AdminCategory);
