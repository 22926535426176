// import React, { useCallback, useState, useEffect } from "react";
// import { IoClose } from "react-icons/io5";
// import { toast } from "react-toastify";
// import SelectField from "../../components/Select/Select";
// import {
//   getAllRole,
//   createUser,
//   updateAdmin,
//   updateAdminUser,
// } from "../../apis"; // Replaced getAllPermission with getAllRole
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";

// const AddEdit = ({
//   fetch,
//   data,
//   logoutRequest,
//   startLoadingRequest,
//   stopLoadingRequest,
// }) => {
//   const [open, setOpen] = useState(false);
//   const [formData, setFormData] = useState({
//     firstName: data?.firstName || "",
//     lastName: data?.lastName || "",
//     email: data?.email || "",
//     role: data?.role || { value: "", label: "" },
//     gender: { value: data?.gender || "", label: data?.gender || "" },
//     password: "",
//   });

//   const [errors, setErrors] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     role: "",
//     gender: "",
//     password: "",
//   });

//   const [roles, setRoles] = useState([]);

//   const openHandler = useCallback(() => {
//     setOpen(!open);
//   }, [open]);

//   const changeHandler = useCallback(
//     (e) => {
//       setFormData({
//         ...formData,
//         [e.target.name]: e.target.value,
//       });
//     },
//     [formData]
//   );

//   const selectDropdownChangeHandler = useCallback(
//     (name, value) => {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     },
//     [formData]
//   );

//   const fetchRoles = useCallback(async () => {
//     try {
//       const apiResponse = await getAllRole(); // Fetching all roles
//       if (apiResponse?.data?.data?.length > 0) {
//         setRoles(
//           apiResponse.data.data.map((el) => ({
//             value: el._id,
//             label: el.name,
//           }))
//         );
//       } else {
//         setRoles([]);
//       }
//     } catch (error) {
//       if (error?.response?.status === 401) {
//         toast.error(error.response.data.message);
//         logoutRequest();
//       } else if (error?.response?.status === 403) {
//         toast.error(error.response.data.message);
//       }
//     }
//   }, [logoutRequest]);

//   useEffect(() => {
//     if (open) {
//       fetchRoles(); // Fetch roles when the modal is opened
//     }
//   }, [open, fetchRoles]);

//   const submitHandler = useCallback(async () => {
//     try {
//       startLoadingRequest();
//       let cleanData = {
//         firstName: formData?.firstName?.trim(),
//         lastName: formData?.lastName?.trim(),
//         email: formData?.email?.trim(),
//         roleId: formData?.role?.value, // Using roleId instead of permissions
//         gender: formData?.gender?.value,
//         ...(!data?._id && { password: formData?.password?.trim() }),
//       };
//       let tempErrors = {};
//       if (!cleanData.firstName) {
//         tempErrors["firstName"] = "First name is required";
//       }
//       if (!cleanData.lastName) {
//         tempErrors["lastName"] = "Last name is required";
//       }
//       if (!data?._id && cleanData.password?.length < 8) {
//         tempErrors["password"] = "Password must have 8 characters";
//       }
//       if (!cleanData.email) {
//         tempErrors["email"] = "Email is required";
//       }
//       if (!cleanData.gender) {
//         tempErrors["gender"] = "Gender is required";
//       }
//       if (!cleanData.roleId) {
//         tempErrors["role"] = "Role is required";
//       }
//       if (Object.keys(tempErrors)?.length > 0) {
//         setErrors(tempErrors);
//         stopLoadingRequest();
//         return;
//       } else {
//         setErrors({});
//       }
//       if (data?._id) {
//         await updateAdminUser(data?._id, cleanData);
//       } else {
//         await createUser(cleanData); // Using createUser to create a new admin user
//       }
//       setOpen(false);
//       setFormData({
//         firstName: "",
//         lastName: "",
//         email: "",
//         role: { value: "", label: "" },
//         gender: { value: "", label: "" },
//         password: "",
//       });
//       setErrors({
//         firstName: "",
//         lastName: "",
//         email: "",
//         role: "",
//         gender: "",
//         password: "",
//       });
//       fetch();
//     } catch (error) {
//       stopLoadingRequest();
//       toast.error(error?.response?.data?.message);
//       if (error?.response?.status === 401) {
//         logoutRequest();
//       }
//     }
//   }, [
//     formData,
//     data,
//     fetch,
//     startLoadingRequest,
//     stopLoadingRequest,
//     logoutRequest,
//   ]);

//   useEffect(() => {
//     if (data?._id) {
//       setFormData({
//         firstName: data?.firstName || "",
//         lastName: data?.lastName || "",
//         email: data?.email || "",
//         role: data?.role || { value: "", label: "" },
//         gender: { value: data?.gender || "", label: data?.gender || "" },
//       });
//     } else {
//       setFormData({
//         firstName: "",
//         lastName: "",
//         email: "",
//         role: { value: "", label: "" },
//         gender: { value: "", label: "" },
//         password: "",
//       });
//     }
//   }, [data?._id]);

//   return (
//     <div className="modal-container">
//       <div className="add-modal">
//         <button
//           className={data?._id ? "secondary" : "add"}
//           onClick={openHandler}
//         >
//           {data?._id ? "Edit" : "Add"}
//         </button>
//       </div>
//       <div className={open ? "modal active" : "modal"}>
//         <div className="wrapper">
//           <div className="header">
//             <h2>{data?._id ? "Edit Admin" : "Add Admin"}</h2>
//             <button type="button" className="close" onClick={openHandler}>
//               <IoClose size={18} />
//             </button>
//           </div>
//           <div className="content">
//             <div className="form-section">
//               <div className="input-wrapper">
//                 <label>First Name</label>
//                 <input
//                   name="firstName"
//                   value={formData.firstName}
//                   onChange={changeHandler}
//                 />
//                 {errors.firstName && <p>{errors.firstName}</p>}
//               </div>
//               <div className="input-wrapper">
//                 <label>Last Name</label>
//                 <input
//                   name="lastName"
//                   value={formData.lastName}
//                   onChange={changeHandler}
//                 />
//                 {errors.lastName && <p>{errors.lastName}</p>}
//               </div>
//               <div className="input-wrapper">
//                 <label>Email</label>
//                 <input
//                   name="email"
//                   value={formData.email}
//                   onChange={changeHandler}
//                 />
//                 {errors.email && <p>{errors.email}</p>}
//               </div>
//               {!data?._id && (
//                 <div className="input-wrapper">
//                   <label>Password</label>
//                   <input
//                     name="password"
//                     value={formData.password}
//                     onChange={changeHandler}
//                   />
//                   {errors.password && <p>{errors.password}</p>}
//                 </div>
//               )}
//               <div className="input-wrapper">
//                 <label>Gender</label>
//                 <SelectField
//                   name="gender"
//                   onChange={selectDropdownChangeHandler}
//                   value={formData.gender}
//                   isMulti={false}
//                   options={[
//                     {
//                       value: "Male",
//                       label: "Male",
//                     },
//                     {
//                       value: "Female",
//                       label: "Female",
//                     },
//                     {
//                       value: "Not Specified",
//                       label: "Not Specified",
//                     },
//                   ]}
//                 />
//                 {errors.gender && <p>{errors.gender}</p>}
//               </div>
//               <div className="input-wrapper">
//                 <label>Role</label>
//                 <SelectField
//                   name="role"
//                   onChange={selectDropdownChangeHandler}
//                   value={formData.role}
//                   isMulti={false}
//                   options={roles} // Populating roles in the dropdown
//                 />
//                 {errors.role && <p>{errors.role}</p>}
//               </div>
//             </div>
//           </div>
//           <div className="footer">
//             <button type="button" onClick={submitHandler} className="primary">
//               Submit
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       logoutRequest: actions.auth.logout,
//       startLoadingRequest: actions.loader.startLoading,
//       stopLoadingRequest: actions.loader.stopLoading,
//     },
//     dispatch
//   );

// export default connect(null, mapDispatchToProps)(AddEdit);

import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SelectField from "../../components/Select/Select";
import { getAllRole, createUser, updateAdminUser } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email || "",
    role: data?.role || { value: "", label: "" },
    gender: { value: data?.gender || "", label: data?.gender || "" },
    password: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    gender: "",
    password: "",
  });

  const [roles, setRoles] = useState([]);

  const openHandler = useCallback(() => {
    if (data?._id) {
      setFormData({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        email: data?.email || "",
        role: data?.role || { value: "", label: "" },
        gender: { value: data?.gender || "", label: data?.gender || "" },
      });
    }
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const fetchRoles = useCallback(async () => {
    try {
      const apiResponse = await getAllRole();
      if (apiResponse?.data?.data?.length > 0) {
        setRoles(
          apiResponse.data.data.map((el) => ({
            value: el._id,
            label: el.name,
          }))
        );
      } else {
        setRoles([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
    }
  }, [logoutRequest]);

  useEffect(() => {
    if (open) {
      fetchRoles();
    }
  }, [open, fetchRoles]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();

      let cleanData = {
        firstName: formData?.firstName?.trim(),
        lastName: formData?.lastName?.trim(),
        email: formData?.email?.trim(),
        roleId: formData?.role?.value,
        gender: formData?.gender?.value,
        userId: data?._id,
        ...(formData.password && { password: formData.password.trim() }), // Include password only if provided
      };

      let tempErrors = {};
      if (!cleanData.firstName) {
        tempErrors["firstName"] = "First name is required";
      }
      if (!cleanData.lastName) {
        tempErrors["lastName"] = "Last name is required";
      }

      if (!cleanData.email) {
        tempErrors["email"] = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(cleanData.email)) {
        tempErrors["email"] = "Email is invalid";
      }

      if (!data?._id && cleanData.password?.length < 8) {
        tempErrors["password"] = "Password must have 8 characters";
      }
      if (!cleanData.gender) {
        tempErrors["gender"] = "Gender is required";
      }
      if (!cleanData.roleId) {
        tempErrors["role"] = "Role is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }

      if (data?._id) {
        await updateAdminUser(cleanData);
        toast.success("Admin updated successfully");
      } else {
        await createUser(cleanData); // Create a new admin user
        toast.success("Admin created successfully");
      }

      setOpen(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        role: { value: "", label: "" },
        gender: { value: "", label: "" },
        password: "",
      });
      setErrors({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        gender: "",
        password: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message || "Failed to save admin");
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [
    formData,
    data,
    fetch,
    startLoadingRequest,
    stopLoadingRequest,
    logoutRequest,
  ]);

  useEffect(() => {
    if (data?._id) {
      setFormData({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        email: data?.email || "",
        role: data?.role || { value: "", label: "" },
        gender: { value: data?.gender || "", label: data?.gender || "" },
      });
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        role: { value: "", label: "" },
        gender: { value: "", label: "" },
        password: "",
      });
    }
  }, [data?._id]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?._id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?._id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?._id ? "Edit Admin" : "Add Admin"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>First Name</label>
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={changeHandler}
                />
                {errors.firstName && <p>{errors.firstName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Last Name</label>
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={changeHandler}
                />
                {errors.lastName && <p>{errors.lastName}</p>}
              </div>
              <div className="input-wrapper">
                <label>Email</label> {/* Add email label */}
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
                {errors.email && <p>{errors.email}</p>} {/* Show email error */}
              </div>
              {!data?._id && (
                <div className="input-wrapper">
                  <label>Password</label>
                  <input
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={changeHandler}
                  />
                  {errors.password && <p>{errors.password}</p>}
                </div>
              )}
              <div className="input-wrapper">
                <label>Gender</label>
                <SelectField
                  name="gender"
                  onChange={selectDropdownChangeHandler}
                  value={formData.gender}
                  isMulti={false}
                  options={[
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Not Specified",
                      label: "Not Specified",
                    },
                  ]}
                />
                {errors.gender && <p>{errors.gender}</p>}
              </div>
              <div className="input-wrapper">
                <label>Role</label>
                <SelectField
                  name="role"
                  onChange={selectDropdownChangeHandler}
                  value={formData.role}
                  isMulti={false}
                  options={roles}
                />
                {errors.role && <p>{errors.role}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
