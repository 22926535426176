






import React, { useState, useRef, useEffect } from 'react';
import { uploadFile, createOutfitByCoach, getCategorySubcategory, coachWardrob, getUserById, uploadAndRemoveBg, createCoachWardrob } from "../../apis";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import style from "./OutfitEditor.css"
import "./OutfitEditor.css"

function OutfitEditor(props) {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])

    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedsubCategory, setSelectedsubCategory] = useState('')
    const [myWardrobModel, setMyWardrobModel] = useState(false)
    const [userWardrobModel, setUserWardrobModel] = useState(false)
    const [myWardrobeItems, setMyWardrobeItems] = useState([]);
    const [userWardrobeItems, setUserWardrobeItems] = useState([]);
    const [wardrobUrl, setWardrobUrl] = useState([])
    const [newWardrobItem, setNewWardrobItem] = useState([])
    //   const [newImageUrl, setNewImageUrl] = useState('');
    // console.log(selectedCategory)
    const fileInputRef = useRef(null);

    const [combinedImageUrl, setCombinedImageUrl] = useState(null);
    const editorRef = useRef(null);
    const dragRef = useRef({ id: null, startX: 0, startY: 0, offsetX: 0, offsetY: 0 });









    const handleFileChange = async (e,id, type) => {
        let newImageUrl;

        if (type === "url") {
            newImageUrl = e;
            setWardrobUrl((prev) => [...prev, {url:e,itemId:id}])
        } else if (type === "file") {
            const file = e.target.files[0];
            setNewWardrobItem((item) => [...item, file])
            newImageUrl = URL.createObjectURL(file);
        }

        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            const newImage = {
                id: Date.now().toString(),
                url: newImageUrl,
                x: 0,
                y: 0,
                width: 100,
                height: 100 / aspectRatio,
                rotation: 0,
                zIndex: images.length
            };
            setImages(prevImages => [...prevImages, newImage]);
        };
        img.src = newImageUrl;
    };


    console.log(images, "112")

    const handleMouseDown = (e, id) => {
        e.preventDefault();
        const img = images.find(img => img.id === id);
        if (img) {
            const rect = e.currentTarget.getBoundingClientRect();
            dragRef.current = {
                id,
                startX: e.clientX,
                startY: e.clientY,
                offsetX: e.clientX - rect.left,
                offsetY: e.clientY - rect.top
            };
            bringToFront(id);
            setSelectedImage(img);
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }
    };

    const handleMouseMove = (e) => {
        if (dragRef.current.id && editorRef.current) {
            const editorRect = editorRef.current.getBoundingClientRect();
            const img = images.find(img => img.id === dragRef.current.id);

            let newX = e.clientX - editorRect.left - dragRef.current.offsetX;
            let newY = e.clientY - editorRect.top - dragRef.current.offsetY;

            newX = Math.max(0, Math.min(newX, editorRect.width - img.width));
            newY = Math.max(0, Math.min(newY, editorRect.height - img.height));

            setImages(prevImages => prevImages.map(img =>
                img.id === dragRef.current.id
                    ? { ...img, x: newX, y: newY }
                    : img
            ));
            setSelectedImage(prevSelected => ({ ...prevSelected, x: newX, y: newY }));
        }
    };

    const handleMouseUp = () => {
        dragRef.current = { id: null, startX: 0, startY: 0, offsetX: 0, offsetY: 0 };
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
    };

    const rotateImage = () => {
        if (selectedImage) {
            const updatedImages = images.map(img =>
                img.id === selectedImage.id
                    ? { ...img, rotation: (img.rotation + 90) % 360 }
                    : img
            );
            setImages(updatedImages);
            setSelectedImage({ ...selectedImage, rotation: (selectedImage.rotation + 90) % 360 });
        }
    };

    const resizeImage = (scale) => {
        if (selectedImage) {
            const updatedImages = images.map(img =>
                img.id === selectedImage.id
                    ? { ...img, width: img.width * scale, height: img.height * scale }
                    : img
            );
            setImages(updatedImages);
            setSelectedImage({ ...selectedImage, width: selectedImage.width * scale, height: selectedImage.height * scale });
        }
    };

    const deleteImage = () => {
        if (selectedImage) {
            setImages(images.filter(img => img.id !== selectedImage.id));
            setSelectedImage(null);
        }
    };


    const saveImage = async () => {
        if (images.length === 0) {
            alert('No images to combine');
            return;
        }

        if(!selectedCategory){
            toast.error("Please select category")
            return
        }
        if(!selectedsubCategory){
            toast.error("Please select subcategory")
            return
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const editorRect = editorRef.current.getBoundingClientRect();
        canvas.width = editorRect.width;
        canvas.height = editorRect.height;

        const sortedImages = [...images].sort((a, b) => a.zIndex - b.zIndex);

        const loadedImages = await Promise.all(sortedImages.map(img => {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.crossOrigin = 'anonymous';
                image.onload = () => resolve(image);
                image.onerror = reject;
                image.src = img.url;
            });
        }));

        loadedImages.forEach((img, index) => {
            const imgData = sortedImages[index];
            ctx.save();
            ctx.translate(imgData.x + imgData.width / 2, imgData.y + imgData.height / 2);
            ctx.rotate((imgData.rotation * Math.PI) / 180);
            ctx.drawImage(img, -imgData.width / 2, -imgData.height / 2, imgData.width, imgData.height);
            ctx.restore();
        });

        canvas.toBlob(async (blob) => {
            const file = new File([blob], 'combined_image.png', { type: 'image/png' });
            setCombinedImageUrl(URL.createObjectURL(file));
            const formData = new FormData();
            formData.append('file', file);
            let newImageUrl = await uploadFile(formData)

            const userDetails = localStorage.getItem("userDetails");
            const user_id = JSON.parse(userDetails)._id;

            // const wardrobe = images.map((item) => item.url)

            const newWardrobePromises = newWardrobItem.map(async (item) => {
                const formData = new FormData();
                formData.append('file', item);
                let newImageUrl = await uploadFile(formData);
                await createCoachWardrob({image:newImageUrl.data.url})
                return { url: newImageUrl.data.url, itemId: "" };
            });
    
            // Wait for all wardrobe items to finish uploading
            const newWardrobeUrls = await Promise.all(newWardrobePromises);
    
            // Merge the new wardrobe items with the existing ones
            const updatedWardrobUrl = [...wardrobUrl, ...newWardrobeUrls];
    
            // Structure the data to send
            let structureToSend = {
                wardrobeItems: updatedWardrobUrl,
                outfitItem: newImageUrl.data.url,
                categoryId: selectedCategory,
                subCategoryId: selectedsubCategory
            }

            
             props.socket.emit("send_message", "", user_id, props.userId, props.roomId, [],structureToSend);

             setWardrobUrl([])
             setSelectedCategory("")
             setSelectedsubCategory("")
             props.setCreateOutfit(false)

            // let data = { outfits: newImageUrl.data.url, coachId: user_id, user_id: props.userId, wardrobe, categoryId: selectedCategory, subCategoryId: selectedsubCategory }

            // try {
            //     let res = await createOutfitByCoach(data)
            //     if (res.status == 200) {
            //         toast.success(res.data.message)

            //     } else {
            //         toast(res.data.message)
            //     }
            //     // props.socket.emit("send_message", "", user_id,  props.userId, props.roomId, [{url}]);

            // } catch (error) {
            //     console.log(error)
            //     toast.error(error)
            // }

        }, 'image/png');
    };

    const bringToFront = (id) => {
        setImages(prevImages => {
            const maxZIndex = Math.max(...prevImages.map(img => img.zIndex));
            return prevImages.map(img => ({
                ...img,
                zIndex: img.id === id ? maxZIndex + 1 : img.zIndex
            }));
        });
    };
    async function getAllCategoryAndSubcategory() {
        let data = await getCategorySubcategory(props.userId)
        // console.log(data)
        setCategories(data.data.data)
    }
    async function myWardrob() {
        let data = await coachWardrob();
        console.log("data", data)
        setMyWardrobeItems(data?.data?.data)

    }

    async function userWardrobe() {
        let data = await getUserById(props?.userId)
        let userData = data?.data?.data

        setUserWardrobeItems(userData.waredrob)
    }

    useEffect(() => {
        getAllCategoryAndSubcategory()
        myWardrob()
        userWardrobe()
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    return (
        <div style={styles.container}>
            <div style={{ display: "flex" }}>

                <h1 style={styles.title}>Outfit Editor</h1>
                <div onClick={() => props.setCreateOutfit(false)}>

                    <IoMdClose size={30} />

                </div>
            </div>
            <div >



                <div style={{ width: "100%" }}  >
                    <div style={styles.inputContainer}>
                        <input
                            hidden
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e,"", "file")}
                            ref={fileInputRef}
                            style={styles.fileInput}
                        />
                        <button onClick={() => fileInputRef.current.click()} style={styles.button}>
                            Choose Image
                        </button>
                        <button onClick={() => setMyWardrobModel(true)} style={styles.button}>
                            My Wardrobe
                        </button>
                        <button onClick={() => setUserWardrobModel(true)} style={styles.button}>
                            User Wardrobe
                        </button>
                    </div>
                    <div style={{ display: "flex" }} >
                        <div
                            ref={editorRef}
                            style={styles.editor}
                        >
                            {images.map((img) => (
                                <div
                                    key={img.id}
                                    onMouseDown={(e) => handleMouseDown(e, img.id)}
                                    style={{
                                        ...styles.imageContainer,
                                        left: `${img.x}px`,
                                        top: `${img.y}px`,
                                        width: `${img.width}px`,
                                        height: `${img.height}px`,
                                        transform: `rotate(${img.rotation}deg)`,
                                        zIndex: img.zIndex,
                                        border: selectedImage && selectedImage.id === img.id ? '2px solid #007bff' : 'none'
                                    }}
                                >
                                    <img src={img.url} alt="" style={styles.image} />
                                </div>
                            ))}

                        </div>

                        <div style={styles.categorySubCategory} >
                            <div style={styles.category}>
                                <h4 style={styles.categoryH4}>Category</h4>
                                <hr />
                                {
                                    categories ? categories?.map((item) => <div style={{ padding: "1rem", cursor: "pointer" }} key={item._id} onClick={() => { setSelectedCategory(item._id); setSubCategories(item.subcategories) }} className={`${selectedCategory == item._id ? "activeCategory" : ""}`}>{item.name}</div>) : <div>No data</div>
                                }
                            </div>
                            <div style={styles.subCategory}>
                                <h4 style={{ textAlign: "center", padding: "1rem" }}>Sub Category</h4>
                                <hr />
                                {
                                    subCategories?.map((item) => <div style={{ padding: "1rem", cursor: "pointer" }} key={item._id} onClick={() => setSelectedsubCategory(item._id)} className={`${selectedsubCategory == item._id ? "activeCategory" : ""}`}>{item.name}</div>)
                                }
                            </div>

                        </div>
                    </div>

                    <div style={styles.controlsContainer}>
                        <button onClick={rotateImage} style={styles.controlButton} disabled={!selectedImage}>Rotate</button>
                        <button onClick={() => resizeImage(1.1)} style={styles.controlButton} disabled={!selectedImage}>Increase Size</button>
                        <button onClick={() => resizeImage(0.9)} style={styles.controlButton} disabled={!selectedImage}>Decrease Size</button>
                        <button onClick={deleteImage} style={styles.controlButton} disabled={!selectedImage}>Delete</button>
                    </div>
                    <button onClick={saveImage} style={styles.saveButton}>Save and Upload</button>




                </div>

                {/* {combinedImageUrl && (
                    <div style={styles.combinedImageContainer}>
                        <h2>Combined Image:</h2>
                        <img src={combinedImageUrl} alt="Combined" style={styles.combinedImage} />
                    </div>
                )} */}


                {
                    myWardrobModel && (


                        <div style={styles.modal}>
                            <div style={styles.modalContent}>
                                <div style={{display:"flex"}}><h2>My Wardrobe</h2>
                                    <div onClick={() => setMyWardrobModel(false)}>

                                        <IoMdClose size={30} />

                                    </div>
                                </div>
                                <div style={styles.wardrobeItems}>
                                    {myWardrobeItems.map((item) => (
                                        <img
                                            key={item._id}
                                            src={item.image}
                                            alt="Wardrobe item"
                                            style={styles.wardrobeItem}
                                            onClick={() => { handleFileChange(item.image, "","url"); setMyWardrobModel(false) }}
                                        />
                                    ))}
                                </div>
                                {/* <button onClick={() => setShowMyWardrobe(false)} style={styles.closeButton}>Close</button> */}
                            </div>
                        </div>
                    )
                }
                {
                    userWardrobModel && (


                        <div style={styles.modal}>
                            <div style={styles.modalContent}>
                            <div style={{display:"flex"}}><h2>User Wardrobe</h2>
                                    <div onClick={() => setUserWardrobModel(false)}>

                                        <IoMdClose size={30} />

                                    </div>
                                </div>
                                <div style={styles.wardrobeItems}>
                                    {userWardrobeItems.map((item) => (
                                        <img
                                            key={item._id}
                                            src={item.image}
                                            alt="Wardrobe item"
                                            style={styles.wardrobeItem}
                                            onClick={() => { handleFileChange(item.image,item?._id, "url"); setUserWardrobModel(false) }}
                                        />
                                    ))}
                                </div>
                                {/* <button onClick={() => setShowMyWardrobe(false)} style={styles.closeButton}>Close</button> */}
                            </div>
                        </div>
                    )
                }

            </div>

        </div>
    );
}

const styles = {
    flex: {
        display: "flex"
    },
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '80%',
        maxHeight: '80%',
        overflow: 'auto',
    },
    wardrobeItems: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    },
    wardrobeItem: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        cursor: 'pointer',
    },
    container: {
        fontFamily: 'Arial, sans-serif',
        // maxWidth: '800px',
        width: '100%',
        margin: '0 auto',
        padding: '20px',
    },
    title: {
        textAlign: 'center',
        color: '#333',
    },
    inputContainer: {
        display: 'flex',
        marginBottom: '20px',
    },
    input: {
        flex: 1,
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ddd',
        borderRadius: '4px 0 0 4px',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '0 4px 4px 0',
        cursor: 'pointer',
    },
    controlsContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        marginBottom: '20px',
    },
    controlButton: {
        padding: '10px 20px',
        fontSize: '14px',
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    editor: {
        position: 'relative',
        backgroundColor: 'white',
        width: '100%',
        height: '500px',
        // border: '2px dashed #ddd',
        shadow: "1px ",
        marginBottom: '20px',
        overflow: 'hidden',
    },
    imageContainer: {
        position: 'absolute',
        cursor: 'move',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    saveButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'block',
        margin: '0 auto',
    },
    combinedImageContainer: {
        marginTop: '20px',
        textAlign: 'center',
    },
    combinedImage: {
        maxWidth: '100%',
        border: '1px solid #ddd',
        borderRadius: '4px',
    },
    categorySubCategory: {
        border: "1px solid black", height: '500px', width: "50rem", display: "flex", backgroundColor: "white"
    },
    category: {
        border: "1px solid red", height: '500px', width: "100%", overflowY: "scroll"
    },
    categoryH4: {
        textAlign: "center", padding: "1rem"
    },
    subCategory: { border: "1px solid green", height: '500px', width: "100%", overflowY: "scroll" }
};

export default OutfitEditor;