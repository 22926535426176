import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { createSubCategory, getUserById, updateSubCategory } from "../../apis";
import { toast } from "react-toastify";
import { FiMoreVertical } from "react-icons/fi"; // Import the triple dots icon
import "./Moodboard.css";

const MoodboardPage = ({ startLoadingRequest, stopLoadingRequest }) => {
     const { id } = useParams();

    const [moodBoard,setMoodboard] = useState([])
    const [first4,setFirst4] = useState([])
    const fetchCategoriesData = useCallback(async () => {
        try {
          startLoadingRequest();
          const response = await getUserById(id);
          const { moodBoard} = response.data.data;
          setMoodboard(moodBoard || []);
          let updatedData = []
            if(moodBoard?.gallery){
                let data = []
                for(let i = 0;i<4;i++){
                    data.push(moodBoard?.gallery[i])
                }
                updatedData.gallery = data
            }
            if(moodBoard?.pinterest){
                let data = []
                for(let i = 0;i<4;i++){
                    data.push(moodBoard?.pinterest[i])
                }
                updatedData.pinterest = data
            }

          setFirst4(updatedData)
          stopLoadingRequest();
         
        } catch (error) {
          console.error("Error fetching categories:", error);
           
          setMoodboard([]);
           
          stopLoadingRequest();
          
        }
      }, [startLoadingRequest, stopLoadingRequest, id]);
    
      useEffect(() => {
        fetchCategoriesData();
      }, []);
    
 console.log("first4",first4)
  return (
    <>
    <div style={{ display:"flex"}}>
        <div style={{  border:"1px solid red"}}>

        </div>
        <div style={{ border:"1px solid red"}}>

        </div>
       {/* {moodBoard&& <div className="gallery" style={{flex:"1", width:"100%",height:"100%"}}> */}
             
                {/* <div> {moodBoard?moodBoard.gallery?.map((item)=><img style={{width:"100%"}} src={item.image}/>):" "}</div>  */}
                 

             
        {/* </div>} */}
        
    </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(memo(MoodboardPage));




// import React, { useState, useRef, useEffect } from 'react';

// function CustomImageEditor() {
//   const [images, setImages] = useState([]);
//   const [newImageUrl, setNewImageUrl] = useState('');
//   const editorRef = useRef(null);
//   const dragRef = useRef({ id: null, startX: 0, startY: 0, offsetX: 0, offsetY: 0 });

//   const addImage = () => {
//     if (newImageUrl) {
//       const newImage = {
//         id: Date.now().toString(),
//         url: newImageUrl,
//         x: 0,
//         y: 0,
//         width: 100,
//         height: 100,
//         rotation: 0,
//         zIndex: images.length
//       };
//       setImages([...images, newImage]);
//       setNewImageUrl('');
//     }
//   };

//   const handleMouseDown = (e, id) => {
//     e.preventDefault();
//     const img = images.find(img => img.id === id);
//     if (img) {
//       const rect = e.currentTarget.getBoundingClientRect();
//       dragRef.current = {
//         id,
//         startX: e.clientX,
//         startY: e.clientY,
//         offsetX: e.clientX - rect.left,
//         offsetY: e.clientY - rect.top
//       };
//       bringToFront(id);
//       window.addEventListener('mousemove', handleMouseMove);
//       window.addEventListener('mouseup', handleMouseUp);
//     }
//   };

//   const handleMouseMove = (e) => {
//     if (dragRef.current.id && editorRef.current) {
//       const editorRect = editorRef.current.getBoundingClientRect();
//       const img = images.find(img => img.id === dragRef.current.id);
      
//       let newX = e.clientX - editorRect.left - dragRef.current.offsetX;
//       let newY = e.clientY - editorRect.top - dragRef.current.offsetY;

//       // Constrain the image within the canvas
//       newX = Math.max(0, Math.min(newX, editorRect.width - img.width));
//       newY = Math.max(0, Math.min(newY, editorRect.height - img.height));

//       setImages(prevImages => prevImages.map(img =>
//         img.id === dragRef.current.id
//           ? { ...img, x: newX, y: newY }
//           : img
//       ));
//     }
//   };

//   const handleMouseUp = () => {
//     dragRef.current = { id: null, startX: 0, startY: 0, offsetX: 0, offsetY: 0 };
//     window.removeEventListener('mousemove', handleMouseMove);
//     window.removeEventListener('mouseup', handleMouseUp);
//   };

//   const rotateImage = (e, id) => {
//     e.stopPropagation();
//     setImages(images.map(img =>
//       img.id === id
//         ? { ...img, rotation: (img.rotation + 90) % 360 }
//         : img
//     ));
//   };

//   const resizeImage = (e, id, scale) => {
//     e.stopPropagation();
//     setImages(images.map(img =>
//       img.id === id
//         ? { ...img, width: img.width * scale, height: img.height * scale }
//         : img
//     ));
//   };

//   const deleteImage = (e, id) => {
//     e.stopPropagation();
//     setImages(images.filter(img => img.id !== id));
//   };

//   const saveImage = () => {
//     console.log('Saving image...', images);
//     // Implement API call here
//   };

//   const bringToFront = (id) => {
//     setImages(prevImages => {
//       const maxZIndex = Math.max(...prevImages.map(img => img.zIndex));
//       return prevImages.map(img => ({
//         ...img,
//         zIndex: img.id === id ? maxZIndex + 1 : img.zIndex
//       }));
//     });
//   };

//   useEffect(() => {
//     return () => {
//       window.removeEventListener('mousemove', handleMouseMove);
//       window.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, []);

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.title}>Custom Image Editor</h1>
//       <div style={styles.inputContainer}>
//         <input
//           type="text"
//           value={newImageUrl}
//           onChange={(e) => setNewImageUrl(e.target.value)}
//           placeholder="Enter image URL"
//           style={styles.input}
//         />
//         <button onClick={addImage} style={styles.button}>Add Image</button>
//       </div>
//       <div
//         ref={editorRef}
//         style={styles.editor}
//       >
//         {images.map((img) => (
//           <div
//             key={img.id}
//             onMouseDown={(e) => handleMouseDown(e, img.id)}
//             style={{
//               ...styles.imageContainer,
//               left: `${img.x}px`,
//               top: `${img.y}px`,
//               width: `${img.width}px`,
//               height: `${img.height}px`,
//               transform: `rotate(${img.rotation}deg)`,
//               zIndex: img.zIndex
//             }}
//           >
//             <img src={img.url} alt="" style={styles.image} />
//             <div style={styles.imageControls}>
//               <button onClick={(e) => rotateImage(e, img.id)} style={styles.controlButton}>🔄</button>
//               <button onClick={(e) => resizeImage(e, img.id, 1.1)} style={styles.controlButton}>+</button>
//               <button onClick={(e) => resizeImage(e, img.id, 0.9)} style={styles.controlButton}>-</button>
//               <button onClick={(e) => deleteImage(e, img.id)} style={styles.controlButton}>🗑️</button>
//             </div>
//           </div>
//         ))}
//       </div>
//       <button onClick={saveImage} style={styles.saveButton}>Save and Upload</button>
//     </div>
//   );
// }

// const styles = {
//   container: {
//     fontFamily: 'Arial, sans-serif',
//     maxWidth: '800px',
//     margin: '0 auto',
//     padding: '20px',
//   },
//   title: {
//     textAlign: 'center',
//     color: '#333',
//   },
//   inputContainer: {
//     display: 'flex',
//     marginBottom: '20px',
//   },
//   input: {
//     flex: 1,
//     padding: '10px',
//     fontSize: '16px',
//     border: '1px solid #ddd',
//     borderRadius: '4px 0 0 4px',
//   },
//   button: {
//     padding: '10px 20px',
//     fontSize: '16px',
//     backgroundColor: '#007bff',
//     color: 'white',
//     border: 'none',
//     borderRadius: '0 4px 4px 0',
//     cursor: 'pointer',
//   },
//   editor: {
//     position: 'relative',
//     width: '100%',
//     height: '500px',
//     border: '2px dashed #ddd',
//     marginBottom: '20px',
//     overflow: 'hidden',
//   },
//   imageContainer: {
//     position: 'absolute',
//     cursor: 'move',
//   },
//   image: {
//     width: '100%',
//     height: '100%',
//     objectFit: 'contain',
//   },
//   imageControls: {
//     position: 'absolute',
//     top: '5px',
//     right: '5px',
//     display: 'flex',
//     gap: '5px',
//   },
//   controlButton: {
//     padding: '5px',
//     fontSize: '12px',
//     backgroundColor: 'rgba(255, 255, 255, 0.7)',
//     border: '1px solid #ddd',
//     borderRadius: '3px',
//     cursor: 'pointer',
//   },
//   saveButton: {
//     padding: '10px 20px',
//     fontSize: '16px',
//     backgroundColor: '#28a745',
//     color: 'white',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     display: 'block',
//     margin: '0 auto',
//   },
// };

// export default CustomImageEditor;