import React, { useState } from "react";
import "./CategorySubCategory.css";


import SubcategoryPage from "../../pages/Subcategories";
import AdminCategory from "../AdminCategory";
import AdminSubcategory from "../AdminSubcategory";

function CategorySubcategory() {
  const [activeComponent, setActiveComponent] = useState(<AdminCategory />);
  const [active,setActive] = useState("category")

  const handleCategoryClick = () => {
    setActive("category")
    setActiveComponent(<AdminCategory />);
  };
  const handleSubCategoryClick = () => {
    setActive("subCategory")
    setActiveComponent(<AdminSubcategory />);
  };
  return (
    <>
      <div className="category-subcategory-container">
        <div className="button-container">
          <button
            className={`${active=="category"?"category-subcategory-btn-active":"category-subcategory-btn"}`}
            onClick={handleCategoryClick}
          >
            Category
          </button>
          <button
            className={`${active=="subCategory"?"category-subcategory-btn-active":"category-subcategory-btn"}`}
            onClick={handleSubCategoryClick}
          >
            Subcategory
          </button>
        </div>
        <div className="component-render">{activeComponent}</div>
      </div>
    </>
  );
}

export default CategorySubcategory;
