import axios from "../utils/axios";

//AUTH
export const login = (data) => axios("admins/auth/login", "POST", data);
export const getAdminDetails =async  () => await axios("admins/auth/profile", "GET");
export const updateAdminDetails = (data) => axios("auth/profile", "PUT", data);
export const updateAdminImage = (data) =>
  axios("auth/profile/image", "PATCH", data);

export const updateAdminPassword = (data) =>
  axios("auth/profile/change-password", "PATCH", data);

//USERS
export const getUsers = (search) => axios(`users${search}`, "GET");
export const activateDeactivateUser = (id, data) =>
  axios(`users/${id}`, "PATCH", data);

// DOCUMENTS
export const uploadSingleDocument = (file) =>
  axios("documents/single", "POST", file, true);

// SALONS
export const getSalons = () => axios("salons", "GET");
export const updateSalon = (id, data) =>
  axios(`salons/${id}/images`, "PATCH", data);

//ADMINS
export const getAdmins = (search) => axios(`admins${search}`, "GET");
export const getPermissions = () => axios("admins/permissions", "GET");
export const createAdmin = (data) => axios("admins", "POST", data);
export const updateAdmin = (id, data) => axios(`admins/${id}`, "PUT", data);
export const activateDeactivateAdmin = (id, data) =>
  axios(`admins/${id}`, "PATCH", data);
// export const changePassword = (id, data) =>
//   axios(`admins/${id}/password`, "PATCH", data);

//PRODUCT PROMOCODES
export const getProductPromocodes = (search) =>
  axios(`product-promocodes${search}`, "GET");
export const createProductPromocode = (data) =>
  axios("product-promocodes", "POST", data);
export const updateProductPromocode = (id, data) =>
  axios(`product-promocodes/${id}`, "PUT", data);
export const activateDeactivateProductPromocode = (id, data) =>
  axios(`product-promocodes/${id}`, "PATCH", data);

//BANNERS
export const getBanners = (query) => axios(`banners${query}`, "GET");
export const createBanner = (data) => axios("banners", "POST", data);
export const updateBanner = (id, data) => axios(`banners/${id}`, "PUT", data);
export const makeBannerLive = (id) => axios(`banners/${id}/live`, "PATCH");
export const deleteBanner = (id) => axios(`banners/${id}`, "DELETE");

//FAQS
export const getFaqs = (query) => axios(`faqs${query}`, "GET");
export const createFaq = (data) => axios("faqs", "POST", data);
export const updateFaq = (id, data) => axios(`faqs/${id}`, "PUT", data);
export const activateDeactivateFaq = (id, data) =>
  axios(`faqs/${id}`, "PATCH", data);

//PRIVACY POLICIES
export const getPrivacyPolicies = (query) =>
  axios(`privacy-policies${query}`, "GET");
export const createPrivacyPolicy = (data) =>
  axios("privacy-policies", "POST", data);
export const updatePrivacyPolicy = (id, data) =>
  axios(`privacy-policies/${id}`, "PUT", data);
export const makePrivacyPolicyLive = (id) =>
  axios(`privacy-policies/${id}/live`, "PATCH");
export const deletePrivacyPolicy = (id) =>
  axios(`privacy-policies/${id}`, "DELETE");

//TERMS AND CONDITIONS
export const getTermsAndConditions = (query) =>
  axios(`terms-conditions${query}`, "GET");
export const createTermAndCondition = (data) =>
  axios("terms-conditions", "POST", data);
export const updateTermAndCondition = (id, data) =>
  axios(`terms-conditions/${id}`, "PUT", data);
export const makeTermAndConditionLive = (id) =>
  axios(`terms-conditions/${id}/live`, "PATCH");
export const deleteTermAndCondition = (id) =>
  axios(`terms-conditions/${id}`, "DELETE");

//TIPS AND TRICKS
export const getTipsAndTricks = (query) => axios(`tips-tricks${query}`, "GET");
export const createTipAndTrick = (data) => axios("tips-tricks", "POST", data);
export const updateTipAndTrick = (id, data) =>
  axios(`tips-tricks/${id}`, "PUT", data);
export const makeTipAndTrickLive = (id) =>
  axios(`tips-tricks/${id}/live`, "PATCH");
export const deleteTipAndTrick = (id) => axios(`tips-tricks/${id}`, "DELETE");

// SALONS
export const getAppointments = (search) =>
  axios(`salons/appointments${search}`, "GET");

// ORDERS
export const getOrders = (search) => axios(`orders${search}`, "GET");

//DASHBOARD
export const getDashboardData = (search) => axios(`dashboard${search}`, "GET");

export const getActiveUsers = (search) => axios(`admins/activeUsers?days=${search}`, "GET");
export const getSessionsPerDay = (search) => axios(`admins/sessionsPerDay?date=${search}`, "GET");
export const getMinutesPerDay = (search) => axios(`admins/minutesPerDay?date=${search}`, "GET");
export const getNumberOfHours = (search) => axios(`admins/numberOfHours?days=${search}`, "GET");
export const getNumberofAppOpens = (search) => axios(`admins/numberOfAppOpens?days=${search}`, "GET");
export const getDAUByMAURatio = () => axios(`admins/getDauMauRatio`, "GET");
export const getWAUByMAURatio = () => axios(`admins/getWauMauRatio`, "GET");
export const getDAUByTotalUsers = () => axios(`admins/getDauTotalUsersRatio`, "GET");
export const getWAUByTotalUsers = () => axios(`admins/getWauTotalUsersRatio`, "GET");
export const getMAUByTotalUsers = () => axios(`admins/getMauTotalUsersRatio`, "GET");
export const getTotalUsers = () => axios(`admins/getTotalUsers`, "GET");
export const getNumberOfMinutesPerSession = () => axios(`admins/getNumberOfMinutesPerSession`, "GET");

//BLOG CATEGORIES
export const getBlogCategories = () => axios(`blogs/categories`, "GET");
export const getActiveBlogCategories = () =>
  axios(`blogs/categories/active`, "GET");
export const createBlogCategory = (data) =>
  axios("blogs/categories", "POST", data);
export const updateBlogCategory = (id, data) =>
  axios(`blogs/categories/${id}`, "PUT", data);
export const activateDeactivateBlogCategory = (id, data) =>
  axios(`blogs/categories/${id}`, "PATCH", data);

//PRODUCT CATEGORIES
export const getCollections = () => axios(`products/collections`, "GET");
export const getProductCategories = () => axios(`products/categories`, "GET");
export const getActiveProductCategories = () =>
  axios(`products/categories/active`, "GET");
export const createProductCategory = (data) =>
  axios("products/categories", "POST", data);
export const updateProductCategory = (id, data) =>
  axios(`products/categories/${id}`, "PUT", data);
export const activateDeactivateProductCategory = (id, data) =>
  axios(`products/categories/${id}`, "PATCH", data);

//BLOGS
export const getBlogs = (query) => axios(`blogs${query}`, "GET");
export const createBlog = (data) => axios("blogs", "POST", data);
export const updateBlog = (id, data) => axios(`blogs/${id}`, "PUT", data);
export const markBlogAsReviewed = (id) =>
  axios(`blogs/${id}/reviewed`, "PATCH");
export const markBlogAsPublished = (id) =>
  axios(`blogs/${id}/publish`, "PATCH");
export const deleteBlog = (id) => axios(`blogs/${id}`, "DELETE");

// api for forgot password
export const forgotPassword = (data) =>
  axios(`admins/auth/forget_password`, "POST", data);
//api to reset password
export const resetPassword = (data) =>
  axios(`admins/auth/reset_password`, "POST", data);

// api to get all users
export const getAllUser = (page, rowsPerPage,search) =>
  axios(`admins/user/user_details?page=${page}&limit=${rowsPerPage}&search=${search||""}`, "GET");

// api to fetch particular user data
export const getUserById = (data) =>
  axios(`admins/user/user_by_id?user_id=${data}`, "GET");

// api to get all permissions

export const getAllPermission = () =>
  axios(`admins/role/all_permissions`, "GET");

//api to create role

export const createRole = (data) =>
  axios(`admins/role/create_role`, "POST", data);

// api to create user with role

export const createUser = (data) =>
  axios(`admins/role/create_user`, "POST", data);

// api to get all roles

export const getAllRole = () => axios(`admins/role/all_roles`, "GET");

// api to get all users with role

export const getAllAdminUser = (page, rowsPerPage) =>
  axios(`admins/role/all_users?page=${page}&limit=${rowsPerPage}`, "GET");



// api to get and post privacy

export const getAllPolicy = (data) =>
  axios(`admins/privacy/create_privacy`, "POST", data);

export const getPolicy = () => axios(`admins/privacy/get_privacy`, "GET");

//api to create category
export const createCategory = (data) =>
  axios(`admins/user/create_category`, "POST", data);

//api to update category
export const updateCategory = (data) =>
  axios(`admins/user/update_category`, "PUT", data);

//api to create sub category
export const createSubCategory = (data, catid) =>
  axios(`admins/user/categories/${catid}/subcategories`, "POST", data);

//api to update sub categories
export const updateSubCategory = (data) =>
  axios("admins/user/update_subcategories", "PUT", data);

// api to update a role

export const updateRole = (data) =>
  axios(`admins/role/update_role`, "PUT", data);

// api to send all notifications

export const sendNotification = (data) =>
  axios(`admins/notification/create_notification`, "POST", data);

// api to update admin password

export const changeAdminPassword = (data) => axios(``, "POST", data);

//api to get all user all notifications
export const getAllNotificationUser = (page, limit, search) =>
  axios(`admins/notification/all_users?page=${page}&limit=${limit}&search=${search||""}`, "GET");

// api to delete a user and it is used in delete role
export const deleteAdminUser = (data) =>
  axios(`admins/role/delete_role`, "DELETE", data);

// api to update user role

export const updateAdminUser = (data) =>
  axios(`admins/role/update_user`, "PUT", data);

// api to delete Admin user

export const deactivateUser = (data) =>
  axios(`admins/role/delete_user`, "DELETE", data);

// api to delete a role
// export const deleteRole = (data)=>axios(`admins//role/delete_role`,"DELETE",data)

// api to change password

export const changePassword = (data) =>
  axios(`admins/auth/change_password`, "POST", data);

// api to deactive normal user

export const deactivateNormalUser = (data) =>
  axios(`admins/user/deactivate`, "PUT", data);


export const getAllCoach = (data) =>
  axios(`admins/user/get_coach_list?coachId=${data}`, "GET");

// api to get All Admin category and subcategory

export const getAllCategorySubcategory = (status,page,limit)=>axios(`admins/user/admin_category_subcategory?status=${status}&page=${page}&limit=${limit}`,"GET")

// api to get all coach history 

export const getAllCoachHistory =()=>axios(`admins/user/get_assign_coach_list`,"GET")

// api to update current coach
export const updateCoach = (data)=>axios(`admins/user/update_coach`,"PUT",data)

// api to get all coaches drop-down
export const getCoaches =()=>axios(`admins/user/all_coach`,"GET")

//Create outfit in coach

export const createOutfitByCoach = (data)=>axios(`admins/coach/create_outfit`,"POST",data)

//Get all category and subcategory

export const getCategorySubcategory = (user_id)=>axios(`admins/coach/categories_subcategories?user_id=${user_id}`,"GET")

// api to get chat by user id
export const getUserChats =(id,status)=>axios(`admins/user/get_userchat_by_id?userId=${id}&status=${status}`)

export const getSupportUser = () =>
  axios(`admins/user/get_support_user_list`, "GET");


// api to delete category 

export const deleteAdminCategory = (data)=>axios(`users/wardrobes/delete_categories`,"POST",data)


// api to delete admin sub-category

export const deleteAdminSubCategory  = (data)=>axios(`users/wardrobes/delete_subcategories`,"POST",data)


// Upload image
export const uploadFile = (image)=>axios(`users/auth/upload`,"POST",image,true)

export const uploadAndRemoveBg = (image)=>axios(`users/auth/upload/remove`,"POST",image,true)

//Coach

//My wardrob

export const coachWardrob = async ()=>await axios(`admins/coach/coach_wardrobe`,"GET")
export const createCoachWardrob = async (data)=>await axios(`admins/coach/create_coach_wardrobe`,"POST",data)





// import React, { useState, useRef, useEffect } from 'react';
// import { uploadFile, createOutfitByCoach, getCategorySubcategory, coachWardrob, getUserById, uploadAndRemoveBg } from "../../apis";
// import { toast } from "react-toastify";
// import { IoMdClose } from "react-icons/io";
// import style from "./OutfitEditor.css"
// import "./OutfitEditor.css"

// function OutfitEditor(props) {
//     const [images, setImages] = useState([]);
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [categories, setCategories] = useState([])
//     const [subCategories, setSubCategories] = useState([])

//     const [selectedCategory, setSelectedCategory] = useState('')
//     const [selectedsubCategory, setSelectedsubCategory] = useState('')
//     const [myWardrobModel, setMyWardrobModel] = useState(false)
//     const [userWardrobModel, setUserWardrobModel] = useState(false)
//     const [myWardrobeItems, setMyWardrobeItems] = useState([]);
//     const [userWardrobeItems, setUserWardrobeItems] = useState([]);
//     const [wardrobUrl, setWardrobUrl] = useState([])
//     const [newWardrobItem, setNewWardrobItem] = useState([])
//     //   const [newImageUrl, setNewImageUrl] = useState('');
//     // console.log(selectedCategory)
//     const fileInputRef = useRef(null);

//     const [combinedImageUrl, setCombinedImageUrl] = useState(null);
//     const editorRef = useRef(null);
//     const dragRef = useRef({ id: null, startX: 0, startY: 0, offsetX: 0, offsetY: 0 });









//     const handleFileChange = async (e, type) => {
//         let newImageUrl;

//         if (type === "url") {
//             newImageUrl = e;
//             setWardrobUrl((prev) => [...prev, e])
//         } else if (type === "file") {
//             const file = e.target.files[0];
//             setNewWardrobItem((item) => [...item, file])
//             newImageUrl = URL.createObjectURL(file);
//         }

//         const img = new Image();
//         img.onload = () => {
//             const aspectRatio = img.width / img.height;
//             const newImage = {
//                 id: Date.now().toString(),
//                 url: newImageUrl,
//                 x: 0,
//                 y: 0,
//                 width: 100,
//                 height: 100 / aspectRatio,
//                 rotation: 0,
//                 zIndex: images.length
//             };
//             setImages(prevImages => [...prevImages, newImage]);
//         };
//         img.src = newImageUrl;
//     };


//     console.log(images, "112")

//     const handleMouseDown = (e, id) => {
//         e.preventDefault();
//         const img = images.find(img => img.id === id);
//         if (img) {
//             const rect = e.currentTarget.getBoundingClientRect();
//             dragRef.current = {
//                 id,
//                 startX: e.clientX,
//                 startY: e.clientY,
//                 offsetX: e.clientX - rect.left,
//                 offsetY: e.clientY - rect.top
//             };
//             bringToFront(id);
//             setSelectedImage(img);
//             window.addEventListener('mousemove', handleMouseMove);
//             window.addEventListener('mouseup', handleMouseUp);
//         }
//     };

//     const handleMouseMove = (e) => {
//         if (dragRef.current.id && editorRef.current) {
//             const editorRect = editorRef.current.getBoundingClientRect();
//             const img = images.find(img => img.id === dragRef.current.id);

//             let newX = e.clientX - editorRect.left - dragRef.current.offsetX;
//             let newY = e.clientY - editorRect.top - dragRef.current.offsetY;

//             newX = Math.max(0, Math.min(newX, editorRect.width - img.width));
//             newY = Math.max(0, Math.min(newY, editorRect.height - img.height));

//             setImages(prevImages => prevImages.map(img =>
//                 img.id === dragRef.current.id
//                     ? { ...img, x: newX, y: newY }
//                     : img
//             ));
//             setSelectedImage(prevSelected => ({ ...prevSelected, x: newX, y: newY }));
//         }
//     };

//     const handleMouseUp = () => {
//         dragRef.current = { id: null, startX: 0, startY: 0, offsetX: 0, offsetY: 0 };
//         window.removeEventListener('mousemove', handleMouseMove);
//         window.removeEventListener('mouseup', handleMouseUp);
//     };

//     const rotateImage = () => {
//         if (selectedImage) {
//             const updatedImages = images.map(img =>
//                 img.id === selectedImage.id
//                     ? { ...img, rotation: (img.rotation + 90) % 360 }
//                     : img
//             );
//             setImages(updatedImages);
//             setSelectedImage({ ...selectedImage, rotation: (selectedImage.rotation + 90) % 360 });
//         }
//     };

//     const resizeImage = (scale) => {
//         if (selectedImage) {
//             const updatedImages = images.map(img =>
//                 img.id === selectedImage.id
//                     ? { ...img, width: img.width * scale, height: img.height * scale }
//                     : img
//             );
//             setImages(updatedImages);
//             setSelectedImage({ ...selectedImage, width: selectedImage.width * scale, height: selectedImage.height * scale });
//         }
//     };

//     const deleteImage = () => {
//         if (selectedImage) {
//             setImages(images.filter(img => img.id !== selectedImage.id));
//             setSelectedImage(null);
//         }
//     };


//     const saveImage = async () => {
//         if (images.length === 0) {
//             alert('No images to combine');
//             return;
//         }

//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');

//         const editorRect = editorRef.current.getBoundingClientRect();
//         canvas.width = editorRect.width;
//         canvas.height = editorRect.height;

//         const sortedImages = [...images].sort((a, b) => a.zIndex - b.zIndex);

//         const loadedImages = await Promise.all(sortedImages.map(img => {
//             return new Promise((resolve, reject) => {
//                 const image = new Image();
//                 image.crossOrigin = 'anonymous';
//                 image.onload = () => resolve(image);
//                 image.onerror = reject;
//                 image.src = img.url;
//             });
//         }));

//         loadedImages.forEach((img, index) => {
//             const imgData = sortedImages[index];
//             ctx.save();
//             ctx.translate(imgData.x + imgData.width / 2, imgData.y + imgData.height / 2);
//             ctx.rotate((imgData.rotation * Math.PI) / 180);
//             ctx.drawImage(img, -imgData.width / 2, -imgData.height / 2, imgData.width, imgData.height);
//             ctx.restore();
//         });

//         canvas.toBlob(async (blob) => {
//             const file = new File([blob], 'combined_image.png', { type: 'image/png' });
//             setCombinedImageUrl(URL.createObjectURL(file));
//             const formData = new FormData();
//             formData.append('file', file);
//             let newImageUrl = await uploadFile(formData)

//             // const userDetails = localStorage.getItem("userDetails");
//             // const user_id = JSON.parse(userDetails)._id;

//             // const wardrobe = images.map((item) => item.url)


//             newWardrobItem?.map(async (item) => {
//                 const formData = new FormData();
//                 formData.append('file', item);
//                 let newImageUrl = await uploadAndRemoveBg(formData)
//                 console.log(newImageUrl)
//                 setWardrobUrl((item) => [...item, newImageUrl.data.url])
//                 // newImageUrl = newImageUrl.data.url
//             })

//             let structureToSend = {
//                 wardrobeItems: wardrobUrl,
//                 outfitItem: newImageUrl.data.url,
//                 categoryId: selectedCategory,
//                 subCategoryId: selectedsubCategory
//             }

//             console.log(structureToSend, "121212")

//             // let data = { outfits: newImageUrl.data.url, coachId: user_id, user_id: props.userId, wardrobe, categoryId: selectedCategory, subCategoryId: selectedsubCategory }

//             // try {
//             //     let res = await createOutfitByCoach(data)
//             //     if (res.status == 200) {
//             //         toast.success(res.data.message)

//             //     } else {
//             //         toast(res.data.message)
//             //     }
//             //     // props.socket.emit("send_message", "", user_id,  props.userId, props.roomId, [{url}]);

//             // } catch (error) {
//             //     console.log(error)
//             //     toast.error(error)
//             // }

//         }, 'image/png');
//     };

//     const bringToFront = (id) => {
//         setImages(prevImages => {
//             const maxZIndex = Math.max(...prevImages.map(img => img.zIndex));
//             return prevImages.map(img => ({
//                 ...img,
//                 zIndex: img.id === id ? maxZIndex + 1 : img.zIndex
//             }));
//         });
//     };
//     async function getAllCategoryAndSubcategory() {
//         let data = await getCategorySubcategory(props.userId)
//         // console.log(data)
//         setCategories(data.data.data)
//     }
//     async function myWardrob() {
//         let data = await coachWardrob();
//         console.log("data", data)
//         setMyWardrobeItems(data?.data?.data)

//     }

//     async function userWardrobe() {
//         let data = await getUserById(props?.userId)
//         let userData = data?.data?.data

//         setUserWardrobeItems(userData.waredrob)
//     }

//     useEffect(() => {
//         getAllCategoryAndSubcategory()
//         myWardrob()
//         userWardrobe()
//         return () => {
//             window.removeEventListener('mousemove', handleMouseMove);
//             window.removeEventListener('mouseup', handleMouseUp);
//         };
//     }, []);

//     return (
//         <div style={styles.container}>
//             <div style={{ display: "flex" }}>

//                 <h1 style={styles.title}>Outfit Editor</h1>
//                 <div onClick={() => props.setCreateOutfit(!props.createOutfit)}>

//                     <IoMdClose size={30} />

//                 </div>
//             </div>
//             <div >



//                 <div style={{ width: "100%" }}  >
//                     <div style={styles.inputContainer}>
//                         <input
//                             hidden
//                             type="file"
//                             accept="image/*"
//                             onChange={(e) => handleFileChange(e, "file")}
//                             ref={fileInputRef}
//                             style={styles.fileInput}
//                         />
//                         <button onClick={() => fileInputRef.current.click()} style={styles.button}>
//                             Choose Image
//                         </button>
//                         <button onClick={() => setMyWardrobModel(true)} style={styles.button}>
//                             My Wardrobe
//                         </button>
//                         <button onClick={() => setUserWardrobModel(true)} style={styles.button}>
//                             User Wardrobe
//                         </button>
//                     </div>
//                     <div style={{ display: "flex" }} >
//                         <div
//                             ref={editorRef}
//                             style={styles.editor}
//                         >
//                             {images.map((img) => (
//                                 <div
//                                     key={img.id}
//                                     onMouseDown={(e) => handleMouseDown(e, img.id)}
//                                     style={{
//                                         ...styles.imageContainer,
//                                         left: `${img.x}px`,
//                                         top: `${img.y}px`,
//                                         width: `${img.width}px`,
//                                         height: `${img.height}px`,
//                                         transform: `rotate(${img.rotation}deg)`,
//                                         zIndex: img.zIndex,
//                                         border: selectedImage && selectedImage.id === img.id ? '2px solid #007bff' : 'none'
//                                     }}
//                                 >
//                                     <img src={img.url} alt="" style={styles.image} />
//                                 </div>
//                             ))}

//                         </div>

//                         <div style={styles.categorySubCategory} >
//                             <div style={styles.category}>
//                                 <h4 style={styles.categoryH4}>Category</h4>
//                                 <hr />
//                                 {
//                                     categories ? categories?.map((item) => <div style={{ padding: "1rem", cursor: "pointer" }} key={item._id} onClick={() => { setSelectedCategory(item._id); setSubCategories(item.subcategories) }} className={`${selectedCategory == item._id ? "activeCategory" : ""}`}>{item.name}</div>) : <div>No data</div>
//                                 }
//                             </div>
//                             <div style={styles.subCategory}>
//                                 <h4 style={{ textAlign: "center", padding: "1rem" }}>Sub Category</h4>
//                                 <hr />
//                                 {
//                                     subCategories?.map((item) => <div style={{ padding: "1rem", cursor: "pointer" }} key={item._id} onClick={() => setSelectedsubCategory(item._id)} className={`${selectedsubCategory == item._id ? "activeCategory" : ""}`}>{item.name}</div>)
//                                 }
//                             </div>

//                         </div>
//                     </div>

//                     <div style={styles.controlsContainer}>
//                         <button onClick={rotateImage} style={styles.controlButton} disabled={!selectedImage}>Rotate</button>
//                         <button onClick={() => resizeImage(1.1)} style={styles.controlButton} disabled={!selectedImage}>Increase Size</button>
//                         <button onClick={() => resizeImage(0.9)} style={styles.controlButton} disabled={!selectedImage}>Decrease Size</button>
//                         <button onClick={deleteImage} style={styles.controlButton} disabled={!selectedImage}>Delete</button>
//                     </div>
//                     <button onClick={saveImage} style={styles.saveButton}>Save and Upload</button>




//                 </div>

//                 {/* {combinedImageUrl && (
//                     <div style={styles.combinedImageContainer}>
//                         <h2>Combined Image:</h2>
//                         <img src={combinedImageUrl} alt="Combined" style={styles.combinedImage} />
//                     </div>
//                 )} */}


//                 {
//                     myWardrobModel && (


//                         <div style={styles.modal}>
//                             <div style={styles.modalContent}>
//                                 <div style={{display:"flex"}}><h2>My Wardrobe</h2>
//                                     <div onClick={() => setMyWardrobModel(false)}>

//                                         <IoMdClose size={30} />

//                                     </div>
//                                 </div>
//                                 <div style={styles.wardrobeItems}>
//                                     {myWardrobeItems.map((item) => (
//                                         <img
//                                             key={item._id}
//                                             src={item.imageUrl}
//                                             alt="Wardrobe item"
//                                             style={styles.wardrobeItem}
//                                             onClick={() => { handleFileChange(item.image, "url"); setMyWardrobModel(false) }}
//                                         />
//                                     ))}
//                                 </div>
//                                 {/* <button onClick={() => setShowMyWardrobe(false)} style={styles.closeButton}>Close</button> */}
//                             </div>
//                         </div>
//                     )
//                 }
//                 {
//                     userWardrobModel && (


//                         <div style={styles.modal}>
//                             <div style={styles.modalContent}>
//                             <div style={{display:"flex"}}><h2>User Wardrobe</h2>
//                                     <div onClick={() => setUserWardrobModel(false)}>

//                                         <IoMdClose size={30} />

//                                     </div>
//                                 </div>
//                                 <div style={styles.wardrobeItems}>
//                                     {userWardrobeItems.map((item) => (
//                                         <img
//                                             key={item._id}
//                                             src={item.image}
//                                             alt="Wardrobe item"
//                                             style={styles.wardrobeItem}
//                                             onClick={() => { handleFileChange(item.image, "url"); setUserWardrobModel(false) }}
//                                         />
//                                     ))}
//                                 </div>
//                                 {/* <button onClick={() => setShowMyWardrobe(false)} style={styles.closeButton}>Close</button> */}
//                             </div>
//                         </div>
//                     )
//                 }

//             </div>

//         </div>
//     );
// }

// const styles = {
//     flex: {
//         display: "flex"
//     },
//     modal: {
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//     },
//     modalContent: {
//         backgroundColor: 'white',
//         padding: '20px',
//         borderRadius: '5px',
//         maxWidth: '80%',
//         maxHeight: '80%',
//         overflow: 'auto',
//     },
//     wardrobeItems: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         gap: '10px',
//     },
//     wardrobeItem: {
//         width: '100px',
//         height: '100px',
//         objectFit: 'cover',
//         cursor: 'pointer',
//     },
//     container: {
//         fontFamily: 'Arial, sans-serif',
//         // maxWidth: '800px',
//         width: '100%',
//         margin: '0 auto',
//         padding: '20px',
//     },
//     title: {
//         textAlign: 'center',
//         color: '#333',
//     },
//     inputContainer: {
//         display: 'flex',
//         marginBottom: '20px',
//     },
//     input: {
//         flex: 1,
//         padding: '10px',
//         fontSize: '16px',
//         border: '1px solid #ddd',
//         borderRadius: '4px 0 0 4px',
//     },
//     button: {
//         padding: '10px 20px',
//         fontSize: '16px',
//         backgroundColor: '#007bff',
//         color: 'white',
//         border: 'none',
//         borderRadius: '0 4px 4px 0',
//         cursor: 'pointer',
//     },
//     controlsContainer: {
//         display: 'flex',
//         justifyContent: 'center',
//         gap: '10px',
//         marginBottom: '20px',
//     },
//     controlButton: {
//         padding: '10px 20px',
//         fontSize: '14px',
//         backgroundColor: '#28a745',
//         color: 'white',
//         border: 'none',
//         borderRadius: '4px',
//         cursor: 'pointer',
//     },
//     editor: {
//         position: 'relative',
//         backgroundColor: 'white',
//         width: '100%',
//         height: '500px',
//         // border: '2px dashed #ddd',
//         shadow: "1px ",
//         marginBottom: '20px',
//         overflow: 'hidden',
//     },
//     imageContainer: {
//         position: 'absolute',
//         cursor: 'move',
//     },
//     image: {
//         width: '100%',
//         height: '100%',
//         objectFit: 'contain',
//     },
//     saveButton: {
//         padding: '10px 20px',
//         fontSize: '16px',
//         backgroundColor: '#28a745',
//         color: 'white',
//         border: 'none',
//         borderRadius: '4px',
//         cursor: 'pointer',
//         display: 'block',
//         margin: '0 auto',
//     },
//     combinedImageContainer: {
//         marginTop: '20px',
//         textAlign: 'center',
//     },
//     combinedImage: {
//         maxWidth: '100%',
//         border: '1px solid #ddd',
//         borderRadius: '4px',
//     },
//     categorySubCategory: {
//         border: "1px solid black", height: '500px', width: "50rem", display: "flex", backgroundColor: "white"
//     },
//     category: {
//         border: "1px solid red", height: '500px', width: "100%", overflowY: "scroll"
//     },
//     categoryH4: {
//         textAlign: "center", padding: "1rem"
//     },
//     subCategory: { border: "1px solid green", height: '500px', width: "100%", overflowY: "scroll" }
// };

// export default OutfitEditor;