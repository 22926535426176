import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { getPolicy, getAllPolicy } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Cursor from "quill/blots/cursor";

const PrivacyPolicies = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [content, setContent] = useState("");
  const [isEditing, setIsEditing] = useState(true); // Default to true
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog visibility
  const [pendingSubmit, setPendingSubmit] = useState(false); // State to track pending submission

  const fetchPolicy = useCallback(async () => {
    try {
      const response = await getPolicy(); // Fetch the existing policy content
       if (response.data && response.data.data) {
        setContent(response.data.data.policy || ""); // Pre-fill editor with existing policy or show empty
      } else {
        setContent(""); // If no content is found
      }
      setLoading(false);
      stopLoadingRequest();
    } catch (error) {
      console.error("Error fetching policy", error);
      toast.error("Error fetching policy");
      setLoading(false);
      stopLoadingRequest();
    }
  }, [stopLoadingRequest]);

  useEffect(() => {
    startLoadingRequest();
    fetchPolicy();
  }, [startLoadingRequest, fetchPolicy]);

  const handleSubmit = () => {
    if (content.trim() === "") {
      toast.error("Content cannot be empty");
      return;
    }

    setPendingSubmit(true); // Set pending submission state
    setOpenDialog(true); // Open the confirmation dialog
  };

  const handleDialogClose = async (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      setSubmitting(true);
      try {
        const payload = { policy: content }; // Data format expected by the API
        await getAllPolicy(payload); // Send data to the API
        toast.success("Privacy Policy Updated!", {
          backgroundColor: "#28a745",
          color: "#fff",
        });
        setIsEditing(true); // Stay in edit mode after successful update
        fetchPolicy(); // Fetch the updated content after submission
      } catch (error) {
        console.error("Error updating privacy policy:", error);
        toast.error("Error Updating Privacy Policy");
      } finally {
        setSubmitting(false);
      }
    } else {
      setIsEditing(true); // Revert to edit mode if not confirmed
      setContent((prevContent) => prevContent); // Ensure content remains unchanged
    }
    setPendingSubmit(false); // Reset pending submission state
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <div className="section">
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: { xl: "3.8rem" },
          }}
        >
          Privacy Policies
        </Typography>
        <Box
          sx={{
            // padding: 4,
            padding: { md: 4, lg: 6 },
            // maxWidth: 800,
            maxWidth: { xs: "100%", md: 1000, lg: 1600, xl: 1800 },
            width: "100%",
            margin: "auto",
            // height: "50vh",
            height: { xs: "auto", md: "50vh", lg: "60vh", xl: "70vh" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ReactQuill
            value={content}
            readOnly={!isEditing}
            onChange={setContent}
            style={{ height: "100%" }}
          />
          <Box sx={{ display: "flex", gap: 2, mt: { lg: 9, xl: 12 } }}>
            <Button
              sx={{
                backgroundColor: "#090909",
                color: "#dfdfdf",
                "&:hover": {
                  backgroundColor: "#090909",
                  color: "#dfdfdf",
                },
                fontSize: {
                  xl: "1.5rem",
                },
                padding: {
                  xl: "2.1rem 2rem",
                },
                marginTop: {
                  xs: "2rem",
                },
              }}
              variant="contained"
              onClick={handleSubmit}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </div>

      {/* Custom Dialog */}
      <Dialog
        className="dialog"
        open={openDialog}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          <Typography>Do you want to save the changes or cancel?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>No</Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PrivacyPolicies);
