import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { createFaq, updateFaq } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import SelectField from "../../components/Select/Select";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    question: data?.question || "",
    answer: data?.answer || "",
    type: { value: data?.type || "", label: data?.type || "" },
  });

  const [errors, setErrors] = useState({
    question: "",
    answer: "",
    type: "",
  });

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );

  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let cleanData = {
        question: formData?.question?.trim(),
        answer: formData?.answer?.trim(),
        type: formData?.type?.value,
      };
      let tempErrors = {};

      if (!cleanData.answer) {
        tempErrors["answer"] = "answer is required";
      }
      if (!cleanData.question) {
        tempErrors["question"] = "question is required";
      }
      if (!cleanData.type) {
        tempErrors["type"] = "type is required";
      }
      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?._id) {
        await updateFaq(data?._id, cleanData);
        toast.success("Faq updated successfully");
      } else {
        await createFaq(cleanData);
        toast.success("Faq added successfully");
      }
      setOpen(false);
      setFormData({
        question: data?.question || "",
        answer: data?.answer || "",
        type: { value: data?.type || "", label: data?.type || "" },
      });
      setErrors({
        question: "",
        answer: "",
        type: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?._id) {
      setFormData({
        question: data?.question || "",
        answer: data?.answer || "",
        type: { value: data?.type || "", label: data?.type || "" },
      });
    } else {
      setFormData({
        question: "",
        answer: "",
        type: { label: "", value: "" },
      });
    }
  }, [data?._id]);

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?._id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?._id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?._id ? "Edit Faq" : "Add Faq"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>Question</label>
                <input
                  name="question"
                  value={formData.question}
                  onChange={changeHandler}
                />
                {errors.question && <p>{errors.question}</p>}
              </div>
              <div className="input-wrapper">
                <label>Answer</label>
                <input
                  name="answer"
                  value={formData.answer}
                  onChange={changeHandler}
                />
                {errors.answer && <p>{errors.answer}</p>}
              </div>
              <div className="input-wrapper">
                <label>Type</label>
                <SelectField
                  name="type"
                  onChange={selectDropdownChangeHandler}
                  value={formData.type}
                  isMulti={false}
                  options={[
                    {
                      value: "General",
                      label: "General",
                    },
                    {
                      value: "Loyalty",
                      label: "Loyalty",
                    },
                  ]}
                />
                {errors.type && <p>{errors.type}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
