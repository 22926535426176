import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx/index.esm";
import { FaChevronDown } from "react-icons/fa/index.esm";
import { RiLogoutBoxRLine } from "react-icons/ri/index.esm";
import {
  IoLockClosedOutline,
  IoSettingsOutline,
} from "react-icons/io5/index.esm";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { getAdminDetails } from "../../apis";
import "./navbar.css";

const Navbar = ({
  userDetailsRequest,
  adminDetails,
  activateSidebarRequest,
  deactivateSidebarRequest,
  sidebar,
  logoutRequest,
  stopLoadingRequest,
}) => {
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [user,setUser] = useState("");

  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAdminDetails();
      if (apiResponse?.data?.data?.adminDetails) {
        // userDetailsRequest(apiResponse?.data?.data?.adminDetails);
      }
      stopLoadingRequest();
    } catch (error) {}
  }, []);


  useEffect(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (storedUserDetails) {
         userDetailsRequest(storedUserDetails);
         setUser(storedUserDetails)
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1023) {
      activateSidebarRequest();
    }
  }, []);

  const openSidebarHandler = useCallback(() => {
    if (sidebar) {
      deactivateSidebarRequest();
    } else {
      activateSidebarRequest();
    }
  }, [sidebar]);

  const openDropdownHandler = useCallback(() => {
    setOpenDropdown(!openDropdown);
  }, [openDropdown]);

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (
        !dropdownButtonRef?.current?.contains(event.target) &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setOpenDropdown(false);
      }
    });
  }, [dropdownButtonRef, dropdownRef]);

  return (
    <nav>
      <div
        ref={dropdownRef}
        className={openDropdown ? "dropdown active" : "dropdown"}
      >
        <Link to="/settings">
          <IoSettingsOutline size={14} />
          Account Settings
        </Link>
        <Link to="/password">
          <IoLockClosedOutline size={14} />
          Change Password
        </Link>
        <button onClick={logoutRequest}>
          <RiLogoutBoxRLine size={14} />
          Logout
        </button>
      </div>
      <div className="left-wrapper">
        <button
          id="hamburger"
          type="button"
          onClick={() => openSidebarHandler()}
        >
          <RxHamburgerMenu id="hamburger-icon" size={22} />
        </button>
        <img src="/images/logo.png" />
      </div>
      <button
        ref={dropdownButtonRef}
        onClick={openDropdownHandler}
        className="right-wrapper"
      >
        {/* <div className="image-wrapper">
          {adminDetails?.data?.image && <img src={adminDetails?.data?.image} />}
        </div> */}
        {/* <span>{`${adminDetails?.data?.firstName} ${adminDetails?.data?.lastName}`}</span> */}
        <span>{`${user?.firstName} ${user?.lastName}`}</span>
        <FaChevronDown size={13} />
      </button>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  adminDetails: state.auth,
  sidebar: state.sidebar,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userDetailsRequest: actions.auth.userDetails,
      deactivateSidebarRequest: actions.sidebar.deactivateSidebar,
      activateSidebarRequest: actions.sidebar.activateSidebar,
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
