import React, { useState, useEffect, useCallback } from "react";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getAppointments } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";

const Appointments = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { pathname, search } = useLocation();
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAppointments(search);
      if (apiResponse?.data?.data?.appointments?.length > 0) {
        setData({ ...apiResponse?.data?.data });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  const requestTypeHandler = useCallback(
    (event) => {
      navigate(
        `/appointments?page=1&requestType=${event.target.value}&search=${
          search
            ?.split("?")[1]
            ?.split("&")
            ?.filter((el) => el.includes("search"))[0]
            ?.split("=")[1]
            ?.trim() || ""
        }`
      );
    },
    [search]
  );

  const searchChangeHandler = useCallback((event) => {
    navigate(
      `/appointments?page=1&requestType=${
        search
          ?.split("?")[1]
          ?.split("&")
          ?.filter((el) => el.includes("requestType"))[0]
          ?.split("=")[1]
          ?.trim() || "All"
      }&search=${event.target.value}`
    );
  }, []);

  const resetSearchHandler = useCallback(() => {
    navigate(
      `/appointments?page=1&requestType=${
        search
          ?.split("?")[1]
          ?.split("&")
          ?.filter((el) => el.includes("requestType"))[0]
          ?.split("=")[1]
          ?.trim() || "All"
      }&search=`
    );
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startLoadingRequest();
      fetch();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pathname, search]);

  useEffect(() => {
    startLoadingRequest();
  }, []);

  return (
    <div className="section">
      <h1>Appointments</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header flex-start">
            <div className="input-wrapper">
              <select
                value={
                  search
                    ?.split("?")[1]
                    ?.split("&")
                    ?.filter((el) => el.includes("requestType"))[0]
                    ?.split("=")[1] || "All"
                }
                onChange={requestTypeHandler}
              >
                <option value="Completed">Completed</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
            <div className="search">
              <div className="input-wrapper">
                <input
                  value={
                    search
                      ?.split("?")[1]
                      ?.split("&")
                      ?.filter((el) => el.includes("search"))[0]
                      ?.split("=")[1]
                      ?.trim() || ""
                  }
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {search
                  ?.split("?")[1]
                  ?.split("&")
                  ?.filter((el) => el.includes("search"))[0]
                  ?.split("=")[1]
                  ?.trim() && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )}
              </div>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Date</th>
                <th>Invoice Number</th>
                <th>Guest Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Services</th>
                <th>Total amount</th>
              </thead>
              <tbody>
                {data?.appointments?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item?.date}</td>
                    <td>{item?.invoiceNumber}</td>
                    <td>
                      {item?.guest?.firstName} {item?.guest?.lastName}
                    </td>
                    <td>{item?.guest?.phoneNumber}</td>
                    <td>{item?.guest?.email}</td>
                    <td>{item?.services?.join(", ")}</td>
                    <td>{item?.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data?.appointments?.length > 0 && (
            <Pagination pageInfo={data.pageInfo} />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Appointments);
