import React, { useState } from "react";
// import { sendResetPasswordEmail } from "../../apis"; // Assume this API call exists
import { toast } from "react-toastify";

//api 
import { forgotPassword } from "../../apis";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
    const response =  await forgotPassword({email:email});
    console.log(response)
      toast.success("Password reset email sent!");
    } catch (error) {
        console.log(error)
      toast.error("Error sending password reset email");
    }
  };

  return (
    <div className="forgot-password-container">
    <form className="forgot-password-form" onSubmit={submitHandler}>
      <h2>Forgot Password</h2>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button type="submit">Send Reset Email</button>
      <div className="forgot-password-link">
        <a href="/login">Back to Login</a>
      </div>
    </form>
  </div>
  );
};

export default ForgotPassword;
