// // // import React, { useState, useEffect, useCallback } from "react";
// // // import { toast } from "react-toastify";
// // // import {
// // //   TextField,
// // //   Button,
// // //   MenuItem,
// // //   Select,
// // //   Table,
// // //   TableBody,
// // //   TableCell,
// // //   TableContainer,
// // //   TableHead,
// // //   TableRow,
// // //   Paper,
// // //   Typography,
// // //   Box,
// // //   Container,
// // //   TablePagination,
// // //   IconButton,
// // // } from "@mui/material";
// // // import EditIcon from "@mui/icons-material/Edit";
// // // import DeleteIcon from "@mui/icons-material/Delete";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
// // // import { getAllPermission, createRole, getAllRole } from "../../apis"; // Import the getAllRole function
// // // import "./customButton.css"; // Assuming the custom button CSS is in a file named customButton.css

// // // const CreateRole = ({ stopLoadingRequest }) => {
// // //   const [roleName, setRoleName] = useState("");
// // //   const [permissions, setPermissions] = useState([]);
// // //   const [selectedPermissions, setSelectedPermissions] = useState([]);
// // //   const [roles, setRoles] = useState([]);
// // //   const [page, setPage] = useState(0);
// // //   const [rowsPerPage, setRowsPerPage] = useState(5);

// // //   const [errors, setErrors] = useState({
// // //     roleName: "",
// // //     permissions: "",
// // //   });

// // //   // Fetch permissions from API
// // //   const fetchPermissions = useCallback(async () => {
// // //     try {
// // //       const response = await getAllPermission();
// // //       console.log(response);
// // //       setPermissions(response.data.data);
// // //     } catch (error) {
// // //       console.error("Failed to fetch permissions:", error);
// // //     }
// // //   }, []);

// // //   // Fetch roles from API
// // //   const fetchRoles = useCallback(async () => {
// // //     try {
// // //       const response = await getAllRole();
// // //       setRoles(response.data.data); // Access the roles array from response.data.data
// // //     } catch (error) {
// // //       console.error("Failed to fetch roles:", error);
// // //     }
// // //   }, []);

// // //   let tempErrors = {};

// // //   useEffect(() => {
// // //     fetchPermissions();
// // //     fetchRoles(); // Fetch roles when the component mounts
// // //     stopLoadingRequest(); // Stop loading when component mounts
// // //   }, [fetchPermissions, fetchRoles, stopLoadingRequest]);

// // //   const handleCreateRole = async (event) => {
// // //     event.preventDefault(); // Prevent the default form submission behavior
// // //     if (!roleName) {
// // //       tempErrors["roleName"] = "Role name is required";
// // //     }
// // //     if (!permissions) {
// // //       tempErrors["permissions"] = "Permission is required";
// // //     }
// // //     if (Object.keys(tempErrors)?.length > 0) {
// // //       setErrors(tempErrors);
// // //       stopLoadingRequest();
// // //       return;
// // //     } else {
// // //       setErrors({});
// // //     }

// // //     const newRole = { name: roleName, permissions: selectedPermissions };
// // //     try {
// // //       await createRole(newRole);
// // //       setRoles([...roles, newRole]);
// // //       toast.success("Role Created");
// // //       setRoleName("");
// // //       setSelectedPermissions([]);
// // //     } catch (error) {
// // //       console.error("Failed to create role:", error);
// // //       toast.error("Failed to create role");
// // //     }
// // //   };

// // //   const handleEditRole = (role) => {
// // //     setRoleName(role.name);
// // //     selectedPermissions(role.permissions.map((permission) => permission._id));
// // //     setEditingRoleId(role._id);
// // //   };

// // //   const handleDeleteRole = (role) => {
// // //     // Implement delete functionality
// // //   };

// // //   const handleChangePage = (event, newPage) => {
// // //     setPage(newPage);
// // //   };

// // //   const handleChangeRowsPerPage = (event) => {
// // //     setRowsPerPage(+event.target.value);
// // //     setPage(0);
// // //   };

// // //   return (
// // //     <div style={{ display: "flex" }}>
// // //       <Container maxWidth="lg" className="section">
// // //         <Box
// // //           sx={{
// // //             borderRadius: "8px",
// // //             boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
// // //             backgroundColor: "#ffffff",
// // //             padding: "20px",
// // //           }}
// // //         >
// // //           <Typography variant="h5" component="h1" gutterBottom>
// // //             Create Role
// // //           </Typography>
// // //           <Box
// // //             component="form"
// // //             onSubmit={handleCreateRole} // Add the onSubmit handler
// // //             display="flex"
// // //             flexDirection="column"
// // //             gap={3}
// // //             mb={4}
// // //           >
// // //             <Box>
// // //               <label
// // //                 style={{
// // //                   display: "block",
// // //                   marginBottom: "8px",
// // //                   fontWeight: "500",
// // //                 }}
// // //               >
// // //                 Role Name
// // //               </label>
// // //               <div className="input-wrapper">
// // //                 <TextField
// // //                   variant="outlined"
// // //                   value={roleName}
// // //                   onChange={(e) => setRoleName(e.target.value)}
// // //                   size="medium"
// // //                   fullWidth
// // //                   sx={{ fontSize: "0.875rem" }}
// // //                   inputProps={{
// // //                     style: { padding: "10px 12px" },
// // //                   }}
// // //                 />
// // //                 {errors.roleName && <p>{errors.roleName}</p>}
// // //               </div>
// // //             </Box>
// // //             <Box>
// // //               <label
// // //                 style={{
// // //                   display: "block",
// // //                   marginBottom: "8px",
// // //                   fontWeight: "500",
// // //                 }}
// // //               >
// // //                 Permissions
// // //               </label>
// // //               <div className="input-wrapper">
// // //                 <Select
// // //                   variant="outlined"
// // //                   fullWidth
// // //                   multiple
// // //                   value={selectedPermissions}
// // //                   onChange={(e) => setSelectedPermissions(e.target.value)}
// // //                   renderValue={(selected) =>
// // //                     selected
// // //                       .map(
// // //                         (permId) =>
// // //                           permissions.find(
// // //                             (permission) => permission._id === permId
// // //                           )?.name
// // //                       )
// // //                       .join(", ")
// // //                   }
// // //                   size="medium"
// // //                   sx={{ fontSize: "0.875rem" }}
// // //                 >
// // //                   {permissions.map((permission) => (
// // //                     <MenuItem key={permission._id} value={permission._id}>
// // //                       {permission.name}
// // //                     </MenuItem>
// // //                   ))}
// // //                 </Select>
// // //                 {errors.permissions && <p>{errors.permissions}</p>}
// // //               </div>
// // //             </Box>
// // //             <button className="custom-button" type="submit">
// // //               Create Role
// // //             </button>
// // //           </Box>
// // //           <TableContainer component={Paper} className="custom-table-container ">
// // //             <Table>
// // //               <TableHead>
// // //                 <TableRow>
// // //                   <TableCell sx={{ fontSize: "0.875rem" }}>Role</TableCell>
// // //                   <TableCell sx={{ fontSize: "0.875rem" }}>
// // //                     Permissions
// // //                   </TableCell>
// // //                   <TableCell sx={{ fontSize: "0.875rem" }}>Actions</TableCell>
// // //                 </TableRow>
// // //               </TableHead>
// // //               <TableBody>
// // //                 {Array.isArray(roles) && roles.length > 0 ? (
// // //                   roles
// // //                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
// // //                     .map((role, index) => (
// // //                       <TableRow key={index}>
// // //                         <TableCell sx={{ fontSize: "0.875rem" }}>
// // //                           {role.name}
// // //                         </TableCell>
// // //                         <TableCell sx={{ fontSize: "0.875rem" }}>
// // //                           {/* Displaying permissions as badges */}
// // //                           {role.permissions &&
// // //                             role?.permissions.map((permission, idx) => (
// // //                               <span
// // //                                 key={idx}
// // //                                 style={{
// // //                                   marginRight: "8px",
// // //                                   backgroundColor: "#e0e0e0",
// // //                                   padding: "4px 8px",
// // //                                   borderRadius: "16px",
// // //                                   display: "inline-block",
// // //                                 }}
// // //                               >
// // //                                 {permission?.name}
// // //                               </span>
// // //                             ))}
// // //                         </TableCell>
// // //                         <TableCell>
// // //                           <IconButton
// // //                             aria-label="edit"
// // //                             onClick={() => handleEditRole(role)}
// // //                           >
// // //                             <EditIcon fontSize="small" />
// // //                           </IconButton>
// // //                           <IconButton
// // //                             aria-label="delete"
// // //                             onClick={() => handleDeleteRole(role)}
// // //                           >
// // //                             <DeleteIcon fontSize="small" />
// // //                           </IconButton>
// // //                         </TableCell>
// // //                       </TableRow>
// // //                     ))
// // //                 ) : (
// // //                   <TableRow>
// // //                     <TableCell colSpan={3} align="center">
// // //                       No roles available
// // //                     </TableCell>
// // //                   </TableRow>
// // //                 )}
// // //               </TableBody>
// // //             </Table>
// // //             <TablePagination
// // //               rowsPerPageOptions={[5, 10, 25]}
// // //               component="div"
// // //               count={roles.length}
// // //               rowsPerPage={rowsPerPage}
// // //               page={page}
// // //               onPageChange={handleChangePage}
// // //               onRowsPerPageChange={handleChangeRowsPerPage}
// // //             />
// // //           </TableContainer>
// // //         </Box>
// // //       </Container>
// // //     </div>
// // //   );
// // // };

// // // const mapDispatchToProps = (dispatch) =>
// // //   bindActionCreators(
// // //     {
// // //       stopLoadingRequest: actions.loader.stopLoading,
// // //     },
// // //     dispatch
// // //   );

// // // export default connect(null, mapDispatchToProps)(CreateRole);

// // import React, { useState, useEffect, useCallback } from "react";
// // import { toast } from "react-toastify";
// // import {
// //   TextField,
// //   Button,
// //   MenuItem,
// //   Select,
// //   Table,
// //   TableBody,
// //   TableCell,
// //   TableContainer,
// //   TableHead,
// //   TableRow,
// //   Paper,
// //   Typography,
// //   Box,
// //   Container,
// //   TablePagination,
// //   IconButton,
// // } from "@mui/material";
// // import EditIcon from "@mui/icons-material/Edit";
// // import DeleteIcon from "@mui/icons-material/Delete";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import actions from "../../actions";
// // import {
// //   getAllPermission,
// //   createRole,
// //   getAllRole,
// //   updateRole,
// // } from "../../apis"; // Import the updateRole function
// // import "./customButton.css"; // Assuming the custom button CSS is in a file named customButton.css

// // const CreateRole = ({ stopLoadingRequest }) => {
// //   const [roleName, setRoleName] = useState("");
// //   const [permissions, setPermissions] = useState([]);
// //   const [selectedPermissions, setSelectedPermissions] = useState([]);
// //   const [roles, setRoles] = useState([]);
// //   const [page, setPage] = useState(0);
// //   const [rowsPerPage, setRowsPerPage] = useState(5);
// //   const [editingRoleId, setEditingRoleId] = useState(null);

// //   const [errors, setErrors] = useState({
// //     roleName: "",
// //     permissions: "",
// //   });

// //   // Fetch permissions from API
// //   const fetchPermissions = useCallback(async () => {
// //     try {
// //       const response = await getAllPermission();
// //       setPermissions(response.data.data);
// //     } catch (error) {
// //       console.error("Failed to fetch permissions:", error);
// //     }
// //   }, []);

// //   // Fetch roles from API
// //   const fetchRoles = useCallback(async () => {
// //     try {
// //       const response = await getAllRole();
// //       setRoles(response.data.data); // Access the roles array from response.data.data
// //     } catch (error) {
// //       console.error("Failed to fetch roles:", error);
// //     }
// //   }, []);

// //   useEffect(() => {
// //     fetchPermissions();
// //     fetchRoles(); // Fetch roles when the component mounts
// //     stopLoadingRequest(); // Stop loading when component mounts
// //   }, [fetchPermissions, fetchRoles, stopLoadingRequest]);

// //   const handleCreateRole = async (event) => {
// //     event.preventDefault(); // Prevent the default form submission behavior
// //     let tempErrors = {};

// //     if (!roleName) {
// //       tempErrors["roleName"] = "Role name is required";
// //     }
// //     if (!selectedPermissions.length) {
// //       tempErrors["permissions"] = "At least one permission is required";
// //     }
// //     if (Object.keys(tempErrors)?.length > 0) {
// //       setErrors(tempErrors);
// //       stopLoadingRequest();
// //       return;
// //     } else {
// //       setErrors({});
// //     }

// //     try {
// //       if (editingRoleId) {
// //         // Update existing role
// //         await updateRole({
// //           name: roleName,
// //           permissions: selectedPermissions,
// //           roleId: editingRoleId,
// //         });
// //         toast.success("Role Updated Successfully");
// //       } else {
// //         // Create new role
// //         await createRole({ name: roleName, permissions: selectedPermissions });
// //         toast.success("Role Created Successfully");
// //       }
// //       setRoleName("");
// //       setSelectedPermissions([]);
// //       setEditingRoleId(null);
// //       fetchRoles();
// //     } catch (error) {
// //       console.error("Failed to save role:", error);
// //       toast.error("Failed to save role");
// //     }
// //   };

// //   const handleEditRole = (role) => {
// //     setRoleName(role.name);
// //     setSelectedPermissions(
// //       role.permissions.map((permission) => permission._id)
// //     );
// //     setEditingRoleId(role._id); // Store the role ID to know that we are in edit mode
// //   };

// //   const handleDeleteRole = (role) => {
// //     // Implement delete functionality
// //   };

// //   const handleChangePage = (event, newPage) => {
// //     setPage(newPage);
// //   };

// //   const handleChangeRowsPerPage = (event) => {
// //     setRowsPerPage(+event.target.value);
// //     setPage(0);
// //   };

// //   return (
// //     <div style={{ display: "flex" }}>
// //       <Container maxWidth="lg" className="section">
// //         <Box
// //           sx={{
// //             borderRadius: "8px",
// //             boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
// //             backgroundColor: "#ffffff",
// //             padding: "20px",
// //           }}
// //         >
// //           <Typography variant="h5" component="h1" gutterBottom>
// //             {editingRoleId ? "Edit Role" : "Create Role"}
// //           </Typography>
// //           <Box
// //             component="form"
// //             onSubmit={handleCreateRole} // Add the onSubmit handler
// //             display="flex"
// //             flexDirection="column"
// //             gap={3}
// //             mb={4}
// //           >
// //             <Box>
// //               <label
// //                 style={{
// //                   display: "block",
// //                   marginBottom: "8px",
// //                   fontWeight: "500",
// //                 }}
// //               >
// //                 Role Name
// //               </label>
// //               <div className="input-wrapper">
// //                 <TextField
// //                   variant="outlined"
// //                   value={roleName}
// //                   onChange={(e) => setRoleName(e.target.value)}
// //                   size="medium"
// //                   fullWidth
// //                   sx={{ fontSize: "0.875rem" }}
// //                   inputProps={{
// //                     style: { padding: "10px 12px" },
// //                   }}
// //                 />
// //                 {errors.roleName && <p>{errors.roleName}</p>}
// //               </div>
// //             </Box>
// //             <Box>
// //               <label
// //                 style={{
// //                   display: "block",
// //                   marginBottom: "8px",
// //                   fontWeight: "500",
// //                 }}
// //               >
// //                 Permissions
// //               </label>
// //               <div className="input-wrapper">
// //                 <Select
// //                   variant="outlined"
// //                   fullWidth
// //                   multiple
// //                   value={selectedPermissions}
// //                   onChange={(e) => setSelectedPermissions(e.target.value)}
// //                   renderValue={(selected) =>
// //                     selected
// //                       .map(
// //                         (permId) =>
// //                           permissions.find(
// //                             (permission) => permission._id === permId
// //                           )?.name
// //                       )
// //                       .join(", ")
// //                   }
// //                   size="medium"
// //                   sx={{ fontSize: "0.875rem" }}
// //                 >
// //                   {permissions.map((permission) => (
// //                     <MenuItem key={permission._id} value={permission._id}>
// //                       {permission.name}
// //                     </MenuItem>
// //                   ))}
// //                 </Select>
// //                 {errors.permissions && <p>{errors.permissions}</p>}
// //               </div>
// //             </Box>
// //             <button className="custom-button" type="submit">
// //               {editingRoleId ? "Update Role" : "Create Role"}
// //             </button>
// //           </Box>
// //           <TableContainer component={Paper} className="custom-table-container ">
// //             <Table>
// //               <TableHead>
// //                 <TableRow>
// //                   <TableCell sx={{ fontSize: "0.875rem" }}>Role</TableCell>
// //                   <TableCell sx={{ fontSize: "0.875rem" }}>
// //                     Permissions
// //                   </TableCell>
// //                   <TableCell sx={{ fontSize: "0.875rem" }}>Actions</TableCell>
// //                 </TableRow>
// //               </TableHead>
// //               <TableBody>
// //                 {Array.isArray(roles) && roles.length > 0 ? (
// //                   roles
// //                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
// //                     .map((role, index) => (
// //                       <TableRow key={index}>
// //                         <TableCell sx={{ fontSize: "0.875rem" }}>
// //                           {role.name}
// //                         </TableCell>
// //                         <TableCell sx={{ fontSize: "0.875rem" }}>
// //                           {/* Displaying permissions as badges */}
// //                           {role.permissions &&
// //                             role?.permissions.map((permission, idx) => (
// //                               <span
// //                                 key={idx}
// //                                 style={{
// //                                   marginRight: "8px",
// //                                   backgroundColor: "#e0e0e0",
// //                                   padding: "4px 8px",
// //                                   borderRadius: "16px",
// //                                   display: "inline-block",
// //                                 }}
// //                               >
// //                                 {permission?.name}
// //                               </span>
// //                             ))}
// //                         </TableCell>
// //                         <TableCell>
// //                           <IconButton
// //                             aria-label="edit"
// //                             onClick={() => handleEditRole(role)}
// //                           >
// //                             <EditIcon fontSize="small" />
// //                           </IconButton>
// //                           <IconButton
// //                             aria-label="delete"
// //                             onClick={() => handleDeleteRole(role)}
// //                           >
// //                             <DeleteIcon fontSize="small" />
// //                           </IconButton>
// //                         </TableCell>
// //                       </TableRow>
// //                     ))
// //                 ) : (
// //                   <TableRow>
// //                     <TableCell colSpan={3} align="center">
// //                       No roles available
// //                     </TableCell>
// //                   </TableRow>
// //                 )}
// //               </TableBody>
// //             </Table>
// //             <TablePagination
// //               rowsPerPageOptions={[5, 10, 25]}
// //               component="div"
// //               count={roles.length}
// //               rowsPerPage={rowsPerPage}
// //               page={page}
// //               onPageChange={handleChangePage}
// //               onRowsPerPageChange={handleChangeRowsPerPage}
// //             />
// //           </TableContainer>
// //         </Box>
// //       </Container>
// //     </div>
// //   );
// // };

// // const mapDispatchToProps = (dispatch) =>
// //   bindActionCreators(
// //     {
// //       stopLoadingRequest: actions.loader.stopLoading,
// //     },
// //     dispatch
// //   );

// // export default connect(null, mapDispatchToProps)(CreateRole);

// import React, { useState, useEffect, useCallback } from "react";
// import { toast } from "react-toastify";
// import {
//   TextField,
//   Button,
//   MenuItem,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Box,
//   Container,
//   TablePagination,
//   IconButton,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";

// import {
//   getAllPermission,
//   createRole,
//   getAllRole,
//   updateRole,
//   deleteAdminUser,
// } from "../../apis"; // Import the updateRole function
// import "./customButton.css"; // Assuming the custom button CSS is in a file named customButton.css

// const CreateRole = ({ stopLoadingRequest }) => {
//   const [roleName, setRoleName] = useState("");
//   const [permissions, setPermissions] = useState([]);
//   const [selectedPermissions, setSelectedPermissions] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [editingRoleId, setEditingRoleId] = useState(null);

//   const [errors, setErrors] = useState({
//     roleName: "",
//     permissions: "",
//   });

//   // Fetch permissions from API
//   const fetchPermissions = useCallback(async () => {
//     try {
//       const response = await getAllPermission();
//       setPermissions(response.data.data);
//     } catch (error) {
//       console.error("Failed to fetch permissions:", error);
//     }
//   }, []);

//   // Fetch roles from API
//   const fetchRoles = useCallback(async () => {
//     try {
//       const response = await getAllRole();
//       setRoles(response.data.data); // Access the roles array from response.data.data
//     } catch (error) {
//       console.error("Failed to fetch roles:", error);
//     }
//   }, []);

import {
  TextField,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Container,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  getAllPermission,
  createRole,
  getAllRole,
  updateRole,
  deleteAdminUser,
} from "../../apis";
import "./customButton.css";
import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

const CreateRole = ({ stopLoadingRequest }) => {
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editingRoleId, setEditingRoleId] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);

  const [errors, setErrors] = useState({
    roleName: "",
    permissions: "",
  });

  const fetchPermissions = useCallback(async () => {
    try {
      const response = await getAllPermission();
      setPermissions(response.data.data);
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
    }
  }, []);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await getAllRole();
      setRoles(response?.data?.data);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  }, []);

  useEffect(() => {
    fetchPermissions();
    fetchRoles();
    stopLoadingRequest();
  }, [fetchPermissions, fetchRoles, stopLoadingRequest]);

  const handleCreateRole = async (event) => {
    event.preventDefault();
    let tempErrors = {};

    if (!roleName) {
      tempErrors["roleName"] = "Role name is required";
    }
    if (!selectedPermissions.length) {
      tempErrors["permissions"] = "At least one permission is required";
    }
    if (Object.keys(tempErrors)?.length > 0) {
      setErrors(tempErrors);
      stopLoadingRequest();
      return;
    } else {
      setErrors({});
    }

    try {
      if (editingRoleId) {
        await updateRole({
          name: roleName,
          permissions: selectedPermissions,
          roleId: editingRoleId,
        });
        toast.success("Role Updated Successfully");
      } else {
        await createRole({ name: roleName, permissions: selectedPermissions });
        toast.success("Role Created Successfully");
      }
      setRoleName("");
      setSelectedPermissions([]);
      setEditingRoleId(null);
      fetchRoles();
    } catch (error) {
      console.error("Failed to save role:", error);
      toast.error("Failed to save role");
    }
  };

  const handleEditRole = (role) => {
    setRoleName(role.name);
    setSelectedPermissions(
      role.permissions.map((permission) => permission._id)
    );
    setEditingRoleId(role._id);
  };

  const handleDeleteRole = async () => {
    try {
      if (roleToDelete) {
        await deleteAdminUser({ roleId: roleToDelete._id });
        toast.success("Role Deleted Successfully");
        fetchRoles();
      }
    } catch (error) {
      console.error("Failed to delete role:", error);
      toast.error("Failed to delete role");
    } finally {
      setConfirmDialogOpen(false);
      setRoleToDelete(null);
    }
  };

  const openConfirmDialog = (role) => {
    setRoleToDelete(role);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setRoleToDelete(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleDeselect = async (event, permission, role) => {
    event.stopPropagation();

    // Filter the permissions for the selected role
    const updatedPermissions = role.permissions
      .filter((perm) => perm._id !== permission._id)
      .map((perm) => perm._id); // Get only the permission IDs

    try {
      // Call updateRole with the filtered permission IDs
      await updateRole({
        name: role.name, // Keep the existing name
        permissions: updatedPermissions, // Pass the updated permission IDs
        roleId: role._id, // Use the role's ID directly
      });

      // Update the roles in the local state after successful update
      setRoles((prevRoles) =>
        prevRoles.map((r) =>
          r._id === role._id ? { ...r, permissions: updatedPermissions } : r
        )
      );

      toast.success("Role Updated Successfully");
      await fetchRoles();
    } catch (error) {
      toast.error("Failed to update role");
    }
  };

  return (
    <div style={{ display: "flex", marginTop: "5rem" }}>
      <Container maxWidth="lg" className="create-role-container">
        <Box
          sx={{
            borderRadius: "8px",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff",
            padding: "20px",
            backgroundColor: "#FAF9F6",
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            {editingRoleId ? "Edit Role" : "Create Role"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleCreateRole}
            display="flex"
            flexDirection="column"
            gap={3}
            mb={4}
          >
            <Box>
              <label
                style={{
                  display: "block",
                  marginBottom: "8px",
                  fontWeight: "500",
                }}
              >
                Role Name
              </label>
              <div className="input-wrapper">
                <TextField
                  margin="none"
                  disableUnderline={true}
                  variant="outlined"
                  value={roleName}
                  onChange={(e) => {
                    setErrors((prev) => ({ ...prev, roleName: "" }));
                    setRoleName(e.target.value);
                  }}
                  size="medium"
                  fullWidth
                  sx={{ fontSize: "0.875rem" }}
                  inputProps={{
                    style: { padding: "10px 12px" },
                  }}
                />
                {errors.roleName && <p>{errors.roleName}</p>}
              </div>
            </Box>
            <Box>
              <label
                style={{
                  display: "block",
                  marginBottom: "8px",
                  fontWeight: "500",
                }}
              >
                Permissions
              </label>
              <div className="input-wrapper">
                <Select
                  sx={{
                    maxHeight: "200px", // Limit the height of the dropdown to 200px
                    overflowY: "auto", // Enable vertical scrolling when the list exceeds max height
                    width: "100%", // Ensure the select box takes full width
                    whiteSpace: "normal", // Allow the text to wrap if needed
                  }}
                  variant="outlined"
                  fullWidth
                  multiple
                  value={selectedPermissions}
                  // onChange={(e) => setSelectedPermissions(e.target.value)}
                  onChange={(e) => {
                    setSelectedPermissions(e.target.value);
                    if (e.target.value.length > 0) {
                      setErrors((prev) => ({
                        ...prev,
                        permissions: "",
                      }));
                    }
                  }}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (permId) =>
                          permissions.find(
                            (permission) => permission._id === permId
                          )?.name
                      )
                      .join(", ")
                  }
                  size="medium"
                  // sx={{ fontSize: "0.875rem" }}
                >
                  {permissions.map((permission) => (
                    <MenuItem key={permission._id} value={permission._id}>
                      {permission.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.permissions && <p>{errors.permissions}</p>}
              </div>
            </Box>
            <button className="custom-button" type="submit">
              {editingRoleId ? "Update Role" : "Create Role"}
            </button>
          </Box>
          <TableContainer
            component={Paper}
            className="custom-table-container "
            sx={{ backgroundColor: "#FAF9F6" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "0.875rem" }}>Role</TableCell>
                  <TableCell sx={{ fontSize: "0.875rem" }}>
                    Permissions
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.875rem" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(roles) && roles.length > 0 ? (
                  roles
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((role, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontSize: "0.875rem" }}>
                          {role.name}
                        </TableCell>
                        <TableCell sx={{ fontSize: "0.875rem" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "8px",
                            }}
                          >
                            {role?.permissions &&
                              role?.permissions.map((permission) => (
                                <Box
                                  key={permission._id}
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: "#e0e0e0",
                                      padding: "4px 8px",
                                      borderRadius: "16px",
                                      display: "inline-block",
                                    }}
                                  >
                                    {permission?.name}
                                    <span
                                      onClick={(event) => {
                                        handleDeselect(event, permission, role);
                                      }}
                                      style={{
                                        marginLeft: "8px",
                                        cursor: "pointer",
                                        color: "grey",
                                      }}
                                    >
                                      x {/* Cross icon */}
                                    </span>
                                  </span>
                                </Box>
                              ))}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleEditRole(role)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => openConfirmDialog(role)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No roles available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={roles.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Container>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to delete this role? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteRole} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CreateRole);
