import constants from "../../utils/constants";

const initialState = false;

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case constants.ACITVATE_SIDEBAR: {
      return true;
    }
    case constants.DEACTIVATE_SIDEBAR: {
      return false;
    }

    default: {
      return state;
    }
  }
};

export default sidebar;
