import constants from "../utils/constants";

const activateSidebar = () => ({
  type: constants.ACITVATE_SIDEBAR,
});

const deactivateSidebar = () => ({
  type: constants.DEACTIVATE_SIDEBAR,
});

export default {
  activateSidebar,
  deactivateSidebar,
};
