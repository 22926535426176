import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getFaqs, activateDeactivateFaq } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import AddEdit from "./AddEdit";

const Faqs = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const [data, setData] = useState({});
  const { pathname, search } = useLocation();
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getFaqs(search);
      if (apiResponse?.data?.data?.faqs?.length > 0) {
        setData({ ...apiResponse?.data?.data });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, [pathname, search]);
  const activateDeactivateFaqHandler = useCallback(async (id, isActive) => {
    try {
      startLoadingRequest();
      await activateDeactivateFaq(id, { requestType: isActive ? "0" : "1" });
      toast.success(
        !isActive
          ? "Faq activated successfully"
          : "Faq deactivated successfully"
      );
      fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, []);

  return (
    <div className="section">
      <h1>FAQS</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <AddEdit fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Type</th>
                <th>Active/Inactive</th>
                <th>Created at</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.faqs?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.question}</td>
                    <td>{item.answer}</td>
                    <td>{item.type}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>{item.createdAt}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive && (
                          <AddEdit
                            type={`TnE-${item._id}`}
                            data={item}
                            fetch={fetch}
                          />
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivateFaqHandler(
                              item._id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Faqs);
