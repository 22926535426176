import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaAngleLeft,
  FaAnglesLeft,
  FaAnglesRight,
  FaAngleRight,
} from "react-icons/fa6/index.esm";

const Pagination = ({ pageInfo }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
     if (pageInfo?.page) {
      pageshandler();
    }
  }, [pageInfo]);

  // const pageshandler = useCallback(() => {
  //   const temp = [];
  //   const tempLastPage =
  //     pageInfo?.total % 20 > 0
  //       ? parseInt(pageInfo?.total / 20) + 1
  //       : parseInt(pageInfo?.total);
  //   setLastPage(tempLastPage);
  //   if (tempLastPage <= 5) {
  //     for (let i = 1; i <= tempLastPage; i++) {
  //       temp.push(i);
  //     }
  //   } else if (tempLastPage - pageInfo.page <= 2) {
  //     console.log("hit");
  //     for (let i = tempLastPage - 4; i <= tempLastPage; i++) {
  //       temp.push(i);
  //     }
  //   } else if (pageInfo.page - 1 <= 2) {
  //     for (let i = 1; i <= 5; i++) {
  //       temp.push(i);
  //     }
  //   } else {
  //     for (let i = pageInfo.page - 2; i <= pageInfo.page + 2; i++) {
  //       temp.push(i);
  //     }
  //   }
  //   setPages(temp);
  // }, [pageInfo]);

  const pageshandler = useCallback(() => {
    const temp = [];
    // Calculate the total number of pages
    const totalPages = Math.ceil(pageInfo?.totaluser / pageInfo?.rowsPerPage);
     setLastPage(totalPages);

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        temp.push(i);
      }
    } else if (totalPages - pageInfo.page <= 2) {
      for (let i = totalPages - 4; i <= totalPages; i++) {
        temp.push(i);
      }
    } else if (pageInfo.page - 1 <= 2) {
      for (let i = 1; i <= 5; i++) {
        temp.push(i);
      }
    } else {
      for (let i = pageInfo.page - 2; i <= pageInfo.page + 2; i++) {
        temp.push(i);
      }
    }
     setPages(temp);
  }, [pageInfo]);

  // const navigationHandler = useCallback(
  //   (page) => {
  //     let tempSearch =
  //       search
  //         ?.split("?")[1]
  //         ?.split("&")
  //         ?.map((el) => {
  //           if (el.includes("page")) {
  //             return `page=${page}`;
  //           } else {
  //             return el;
  //           }
  //         })
  //         ?.join("&") || "";
  //     if (tempSearch) {
  //       navigate(pathname + "?" + tempSearch);
  //     }
  //   },
  //   [pathname, search]
  // );
  const navigationHandler = useCallback(
    (page) => {
       const params = new URLSearchParams(search);

      params.set("page", page);
      navigate(`${pathname}?${params.toString()}`);
    },
    [pathname, search, navigate]
  );
  return (
    <div className="pagination">
      <button
        className={pageInfo?.page !== 1 ? "active" : ""}
        onClick={() =>
          pageInfo?.page !== 1
            ? navigationHandler(
                Math.max(1, pageInfo.page - (pageInfo.page - 1))
              )
            : null
        }
        disabled={Number(pageInfo?.page) === 1}
      >
        <FaAnglesLeft disabled={pageInfo?.page === 1} size={8} />
      </button>
      <button
        className={pageInfo?.page !== 1 ? "active" : ""}
        onClick={() =>
          pageInfo?.page !== 1 ? navigationHandler(pageInfo.page - 1) : null
        }
        disabled={Number(pageInfo?.page) === 1}
      >
        <FaAngleLeft size={8} />
      </button>
      <div className="pages">
        {pages?.map((el) => (
          <button
            key={`${pathname}-${el}`}
            className={
              Number(pageInfo?.page) === el ? "number active" : " active"
            }
            onClick={() =>
              pageInfo?.page !== el ? navigationHandler(el) : null
            }
          >
            <span>{el}</span>
          </button>
        ))}
      </div>
      <button
        className={pageInfo?.page !== lastPage ? "active" : ""}
        onClick={() =>
          pageInfo?.page !== lastPage
            ? navigationHandler(Number(pageInfo?.page) + 1)
            : null
        }
        disabled={Number(pageInfo?.page) === lastPage}
      >
        <FaAngleRight size={8} />
      </button>
      <button
        className={pageInfo?.page !== lastPage ? "active" : ""}
        onClick={() =>
          pageInfo?.page !== lastPage ? navigationHandler(lastPage) : null
        }
        disabled={Number(pageInfo?.page) === lastPage}
      >
        <FaAnglesRight size={8} />
      </button>
      <p>
        Showing {(pageInfo?.page - 1) * pageInfo?.rowsPerPage + 1} to{" "}
        {pageInfo?.page * pageInfo?.rowsPerPage < pageInfo?.totaluser
          ? pageInfo?.page * pageInfo?.rowsPerPage
          : pageInfo?.totaluser}{" "}
        of {pageInfo?.totaluser}
      </p>
    </div>
  );
};

export default Pagination;
