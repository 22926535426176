import constants from "../utils/constants";

const startLoading = () => ({
  type: constants.START_LOADING,
});

const stopLoading = () => ({
  type: constants.STOP_LOADING,
});

export default {
  startLoading,
  stopLoading,
};
