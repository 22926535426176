import React, { useCallback, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import actions from "../../actions";
import { getUserChats } from "../../apis";
import "./ChatHistory.css";

const ChatHistory = ({ startLoadingRequest, stopLoadingRequest }) => {
  const { id } = useParams();
  const [chatMessages, setChatMessages] = useState([]); // Store chat messages

  // Fetch user chat data
  const fetchUserChats = useCallback(async () => {
    try {
      const apiResponse = await getUserChats(id, "coach");
       if (apiResponse?.data?.data) {
        setChatMessages(apiResponse?.data?.data); // Set chat messages from response
      }
    } catch (error) {
      console.error("Error fetching chat messages", error);
    }
  }, [id]);

  useEffect(() => {
    stopLoadingRequest();
    fetchUserChats();
  }, [fetchUserChats, stopLoadingRequest]);

  return (
    <>
      <div className="chat-div">
        <div className="chat-container">
          <div className="chat-history">
            {chatMessages.length > 0 ? (
              chatMessages.map((message, index) => (
                <div
                  key={message._id}
                  className={`chat-message ${
                    message.senderId === id ? "sent" : "received"
                  }`}
                >
                  <p className="messages">{message.message}</p>

                  {/* Display Options if available */}
                  {message.options.length > 0 && (
                    <div className="message-options">
                      {message.options.map((option, idx) => (
                        <button key={idx} className="option-button">
                          {option}
                        </button>
                      ))}
                    </div>
                  )}

                  {/* Display media if available */}
                  {message.media.length > 0 && (
                    <div className="message-media">
                      {message.media.map((mediaUrl, idx) => (
                        <img key={idx} src={mediaUrl} alt="media" />
                      ))}
                    </div>
                  )}

                  {/* Display timestamp */}
                  <small className="timestamp">
                    {new Date(message.creationTime).toLocaleString()}
                  </small>
                </div>
              ))
            ) : (
              <p>No chat history available</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      deactivateSidebarRequest: actions.sidebar.deactivateSidebar,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ChatHistory);
