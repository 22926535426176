module.exports = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  USER_DETAILS: "USER_DETAILS",
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  ACITVATE_SIDEBAR: "ACITVATE_SIDEBAR",
  DEACTIVATE_SIDEBAR: "DEACTIVATE_SIDEBAR",
  ACITVATE_MODAL: "ACITVATE_MODAL",
  DEACTIVATE_MODAL: "DEACTIVATE_MODAL",
  
};
