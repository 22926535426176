import React, { useCallback, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import SelectField from "../../components/Select/Select";
import {
  getActiveBlogCategories,
  createBlog,
  updateBlog,
  uploadSingleDocument,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";

const AddEdit = ({
  fetch,
  data,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    headline: data?.headline || "",
    subHeading: data?.subHeading || "",
    content: data?.content || "",
    docId: data?.document._id || "",
    image: data?.document.url || "",
    file: "",
    categoryId: {
      value: data?.category?._id || "",
      label: data?.category?.name || "",
    },
  });

  const [errors, setErrors] = useState({
    headline: "",
    subHeading: "",
    content: "",
    categoryId: "",
    docId: "",
  });

  const [blogCategories, setBlogCategories] = useState([]);

  const openHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeHandler = useCallback(
    (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData]
  );
  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setFormData({
        ...formData,
        [name]: value,
      });
    },
    [formData]
  );

  const fileUploadHandler = useCallback(async () => {
    if (typeof formData?.file === "object") {
      const form = new FormData();
      form.append("file", formData.file);
      const apiResponse = await uploadSingleDocument(form);
      return apiResponse?.data?.data?.documentId;
    } else {
      return formData?.docId || "";
    }
  }, [formData]);

  const getActiveBlogCategoriesHandler = useCallback(async () => {
    try {
      const apiResponse = await getActiveBlogCategories();
      if (apiResponse?.data?.data?.blogCategories?.length > 0) {
        setBlogCategories([
          ...apiResponse.data.data.blogCategories.map((el) => ({
            value: el._id,
            label: el.name,
          })),
        ]);
      } else {
        setBlogCategories([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  useEffect(() => {
    if (open) {
      getActiveBlogCategoriesHandler();
    }
  }, [open]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      const docId = await fileUploadHandler();
      let cleanData = {
        headline: formData?.headline?.trim(),
        subHeading: formData?.subHeading?.trim(),
        content: formData?.content?.trim(),
        categoryId: formData?.categoryId?.value,
        docId,
      };
      let tempErrors = {};
      if (!cleanData.headline) {
        tempErrors["headline"] = "Headline is required";
      }
      if (!cleanData.docId) {
        tempErrors["headline"] = "Image is required";
      }
      if (!cleanData.subHeading) {
        tempErrors["subHeading"] = "Sub heading is required";
      }
      if (!cleanData.content) {
        tempErrors["content"] = "content is required";
      }
      if (!cleanData.categoryId) {
        tempErrors["categoryId"] = "categoryId is required";
      }

      if (Object.keys(tempErrors)?.length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
      if (data?._id) {
        await updateBlog(data?._id, cleanData);
      } else {
        await createBlog(cleanData);
      }
      setOpen(false);
      setFormData({
        headline: "",
        subHeading: "",
        content: "",
        categoryId: { value: "", label: "" },
        docId: data?.document._id || "",
        image: data?.document.url || "",
        file: "",
      });
      setErrors({
        headline: "",
        subHeading: "",
        content: "",
        categoryId: "",
        docId: "",
        image: "",
        file: "",
      });
      fetch();
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [formData, data]);

  useEffect(() => {
    if (data?._id) {
      setFormData({
        headline: data?.headline || "",
        subHeading: data?.subHeading || "",
        content: data?.content || "",
        docId: data?.document._id || "",
        image: data?.document.url || "",
        file: "",
        categoryId: {
          value: data?.category?._id || "",
          label: data?.category?.name || "",
        },
      });
    } else {
      setFormData({
        headline: "",
        subHeading: "",
        content: "",
        docId: "",
        image: "",
        file: "",
        categoryId: { value: "", label: "" },
      });
    }
  }, [data?._id]);

  const fileHandler = useCallback(
    (event) => {
      if (event?.target?.files[0]) {
        setFormData((prevData) => ({
          ...prevData,
          file: event.target.files[0],
          image: URL.createObjectURL(event.target.files[0]),
        }));
      }
    },
    [formData]
  );

  return (
    <div className="modal-container">
      <div className="add-modal">
        <button
          className={data?._id ? "secondary" : "add"}
          onClick={openHandler}
        >
          {data?._id ? "Edit" : "Add"}
        </button>
      </div>
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{data?._id ? "Edit Admin" : "Add Admin"}</h2>
            <button type="button" className="close" onClick={openHandler}>
              <IoClose size={18} />
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper-image">
                <div className="image">
                  {formData.image && <img src={formData.image} />}
                </div>
                <label htmlFor={type}>Upload Image (Aspect ratio - 3:2)</label>
                <input
                  onChange={fileHandler}
                  accept="Image/*"
                  id={type}
                  type="file"
                />
                {errors.docId && <p>{errors.docId}</p>}
              </div>
              <div className="input-wrapper">
                <label>Headline</label>
                <input
                  name="headline"
                  value={formData.headline}
                  onChange={changeHandler}
                />
                {errors.headline && <p>{errors.headline}</p>}
              </div>
              <div className="input-wrapper">
                <label>Sub heading</label>
                <input
                  name="subHeading"
                  value={formData.subHeading}
                  onChange={changeHandler}
                />
                {errors.subHeading && <p>{errors.subHeading}</p>}
              </div>
              <div className="input-wrapper">
                <label>Content</label>
                <textarea
                  name="content"
                  value={formData.content}
                  onChange={changeHandler}
                />
                {errors.content && <p>{errors.content}</p>}
              </div>
              <div className="input-wrapper">
                <label>Category</label>
                <SelectField
                  name="categoryId"
                  onChange={selectDropdownChangeHandler}
                  value={formData.categoryId}
                  isMulti={false}
                  options={blogCategories}
                />
                {errors.categoryId && <p>{errors.categoryId}</p>}
              </div>
            </div>
          </div>
          <div className="footer">
            <button type="button" onClick={submitHandler} className="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(AddEdit);
