import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { RxCrossCircled } from "react-icons/rx/index.esm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAllUser, deactivateNormalUser, getUserById } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";

const Users = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(null);
  const [totalUser, setTotalUser] = useState(null);
  const [searchFilter, setFilterSearch] = useState([]);
  const [rowsPerPage,setRowsPerPage] = useState(10);
  const { search } = useLocation();

  const fetch = useCallback(
    async (page, rowsPerPage,query) => {
      try {
        startLoadingRequest();
        const apiResponse = await getAllUser(page, rowsPerPage,query); // Pass the current page to the API call
        if (apiResponse?.data?.data?.users?.length > 0) {
          setData(apiResponse?.data?.data?.users);
          setTotal(apiResponse?.data?.data?.totalPages);
          setCurrentPage(apiResponse?.data?.data?.currentPage);
          setPage(apiResponse?.data?.data?.currentPage);
          setTotalUser(apiResponse?.data?.data?.totalUsers);
          setRowsPerPage(apiResponse?.data?.data?.users.length)

          setFilterSearch(apiResponse?.data?.data?.users);
          // setTotal(apiResponse?.data?.data?.totalUsers);
        } else {
          setData([]);
        }
        stopLoadingRequest();
      } catch (error) {
        stopLoadingRequest();
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
      }
    },
    [logoutRequest, startLoadingRequest, stopLoadingRequest, page]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const page = parseInt(queryParams.get("page") || "1", 10); // Get page from URL or default to 1
    setCurrentPage(page);
    fetch(page, rowsPerPage); // Fetch users for the current page
  }, [search]);

  useEffect(() => {
    if (search) {
      setSearchValue(
        search
          ?.split("?")[1]
          ?.split("&")
          ?.filter((el) => el.includes("search"))[0]
          ?.split("=")[1] || ""
      );
    }
    // fetch(page, rowsPerPage);
  }, [search]);

  const activateDeactivateUserHandler = useCallback(
    async (id, requestType) => {
      try {
        startLoadingRequest();
        const status = requestType === 1 ? true : false;
        await deactivateNormalUser({ userId: id, status: status });

        setData((prevData) =>
          prevData.map((user) =>
            user._id === id ? { ...user, isActive: status } : user
          )
        );

        toast.success(
          status
            ? "User activated successfully"
            : "User deactivated successfully"
        );
      } catch (error) {
        stopLoadingRequest();
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
      } finally {
        stopLoadingRequest();
      }
    },
    [logoutRequest, startLoadingRequest, stopLoadingRequest]
  );

  const searchHandler = useCallback(() => {
    // navigate(`/users?page=1&search=${searchValue?.trim()}`);
  }, [searchValue, navigate]);

  const searchChangeHandler = useCallback((event) => {
   
    setSearchValue(event.target.value);
    if(event.target.value){
      fetch(1,100,event.target.value)
    }else{
      fetch(1,10,event.target.value)
      setRowsPerPage(10)
    }
  }, []);

  // useEffect(() => {
  //   if (searchValue) {
  //     const searchUsername = searchFilter?.filter((user) =>
  //       user?.firstName?.toLowerCase().includes(searchValue)
  //     );
  //     setData(searchUsername);
  //   } else {
  //     setData(searchFilter);
  //   }
  // }, [searchValue]);

  const resetSearchHandler = useCallback(() => {
    setSearchValue("");
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      searchHandler();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchValue, searchHandler]);

  const handleRowClick = (id) => {
    navigate(`/users/${id}`);
  };
  const handlePageChange = (newPage) => {
    navigate(`/users?page=${newPage}`); // Update the URL when the page changes
  };

  return (
    <div className="section">
      <h1>Users</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="search">
              <div className="input-wrapper">
                <input
                  value={searchValue}
                  onChange={searchChangeHandler}
                  placeholder="Search..."
                />
                {/* {searchValue?.trim() && (
                  <RxCrossCircled onClick={resetSearchHandler} size={18} />
                )} */}
              </div>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Onboarding date</th>
                  <th>Name</th>
                  <th>Phone number</th>
                  <th>Email</th>
                  <th>Active/Inactive</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={item._id}
                    onClick={() => handleRowClick(item._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{(currentPage - 1) * 10 + index + 1}</td>
                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    <td>
                      <Link to={`/user/${item._id}`} className="no-link-style">
                        {`${item.firstName || "N/A"} ${item.lastName || ""}`}
                      </Link>
                    </td>
                    <td>{item.phoneNumber || "N/A"}</td>
                    <td>{item.email}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <div className="table-actions">
                        {item.isActive ? (
                          <button
                            className="danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              activateDeactivateUserHandler(item._id, 0);
                            }}
                          >
                            Deactivate
                          </button>
                        ) : (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              activateDeactivateUserHandler(item._id, 1);
                            }}
                            className="success"
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data?.length > 0 && (
            <Pagination
              pageInfo={{
                page: currentPage,
                total: total,
                totaluser: totalUser,
                rowsPerPage: rowsPerPage,
              }}
              // onPageChange={() => setPage((prev) => prev + 1)}
              onPageChange={handlePageChange} // Handle page change
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Users);
