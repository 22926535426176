import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
 import { bindActionCreators } from "redux";

import actions from "../../actions";
import { connect } from "react-redux";


import { FaEye } from "react-icons/fa/index.esm";
import { resetPassword } from "../../apis";


const ResetPassword = ({stopLoadingRequest, startLoadingRequest }) => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
 
  const [password,setPassword] = useState("")
  const [newPassword,setNewPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate()


  useEffect(() => {
    if (key) {
   
      stopLoadingRequest()
    }
  }, [key]);

  const submitHandler = async (e) => {
    e.preventDefault();
    startLoadingRequest()
    try {
      if(password !== newPassword){
        toast.error("Password did not match")
        return
      }
      const response = await resetPassword({password:newPassword,token:key})
       if(response?.status===200){
        toast.success("Password reset email sent!");
        navigate('/login')
        return


      }
    
     
    } catch (error) {
      if(error?.response?.status === 400){
        toast.error(error?.response?.data?.message);
        return

      }
      
  
    }finally {
      stopLoadingRequest()
    }
  };

  return (
    <div className="login-container">
      <form className="login-wrapper">
        <div className="header">
          <div className="logo">
            <img src="/images/logo.png" />
          </div>
        </div>
        <div className="content">
          <div className="input-wrapper">
            <label>New Password</label>
            <div className="icon-wrapper">
      
              <input
               type={showPassword ? "text" : "password"}
                name="password"
                onChange={(e)=>{
                  setPassword(e.target.value)
                }}
                value={password}
                placeholder="Enter your New Password"
              />
                   <div
                onClick={() => setShowPassword(!showPassword)}
                className={showPassword ? "icon active" : "icon"}
              >
                <FaEye size={16} />
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <label>Confirm Password</label>
            <div className="icon-wrapper">
   
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={(e)=>{
                  setNewPassword(e.target.value)
                }}
                value={newPassword}
                placeholder="Confirm Your Password"
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                className={showPassword ? "icon active" : "icon"}
              >
                <FaEye size={16} />
              </div>
            </div>
          </div>
        </div>
        <div className="forgot-password-link">
      
      </div>
        <div className="footer">
          <button onClick={submitHandler} type="button" className="primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginRequest: actions.auth.login,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

  export default connect(null, mapDispatchToProps)(ResetPassword);
