import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  getActiveUsers,
  getSessionsPerDay,
  getMinutesPerDay,
  getNumberOfHours,
  getNumberofAppOpens,
  getDAUByMAURatio,
  getWAUByMAURatio,
  getDAUByTotalUsers,
  getWAUByTotalUsers,
  getMAUByTotalUsers,
  getTotalUsers, getNumberOfMinutesPerSession
} from "../../apis"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import "./dashboard.css";

const Dashboard = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [max, setMax] = useState({
    users: 0,
    appointments: 0,
    orders: 0,
    downloads: 0,
    onlineUsers: 0,
    categoryWiseAppointments: 0,
    numberOfItems: 0,
    numberOfOutfits: 0,
    mostWornItems: 0,
    leastWornItems: 0,
    topColorsWardrobe: 0,
    topColorOutfits: 0,
  });

  
  const [data, setData] = useState({});
  const day = 1
  const week = 7
  const month = 30
  let  date = new Date();
  date = (date.setDate(date.getDate()-1))
  date = new Date(date).toISOString()

  // const navigate = useNavigate();
  // const { pathname, search } = useLocation();
  // const fetch = useCallback(async () => {
  //   try {
  //     const apiResponse = await getDashboardData(search);
  //     if (apiResponse?.data?.data) {
  //       setData({ ...apiResponse?.data?.data });

  //       setMax({
  //         users: Math.max(
  //           ...apiResponse?.data?.data?.users?.analytics?.map((el) => el.total)
  //         ),
  //         appointments: Math.max(
  //           ...apiResponse?.data?.data?.appointments?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         orders: Math.max(
  //           ...apiResponse?.data?.data?.orders?.analytics?.map((el) => el.total)
  //         ),
  //         downloads: Math.max(
  //           ...apiResponse?.data?.data?.downloads?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         onlineUsers: Math.max(
  //           ...apiResponse?.data?.data?.onlineUsers?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         categoryWiseAppointments: Math.max(
  //           ...apiResponse?.data?.data?.categoryWiseAppointments?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         numberOfItems: Math.max(
  //           ...apiResponse?.data?.data?.moredata?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         numberOfOutfits: Math.max(
  //           ...apiResponse?.data?.data?.moredata1?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         mostWornItems: Math.max(
  //           ...apiResponse?.data?.data?.moredata1?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         leastWornItems: Math.max(
  //           ...apiResponse?.data?.data?.moredata1?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),

  //         topColorsWardrobe: Math.max(
  //           ...apiResponse?.data?.data?.moredata1?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //         topColorOutfits: Math.max(
  //           ...apiResponse?.data?.data?.moredata1?.analytics?.map(
  //             (el) => el.total
  //           )
  //         ),
  //       });
  //       stopLoadingRequest();
  //     } else {
  //       setData({});
  //       setMax({ users: 0, appointments: 0, orders: 0 });
  //       stopLoadingRequest();
  //     }
  //   } catch (error) {
  //     if (error?.response?.status === 401) {
  //       toast.error(error.response.data.message);
  //       logoutRequest();
  //     } else if (error?.response?.status === 403) {
  //       toast.error(error.response.data.message);
  //     }
  //     stopLoadingRequest();
  //   }
  // }, [, search]);

  // useEffect(() => {
  //   startLoadingRequest();
  //   fetch();
  // }, [pathname, search]);

  // const changehandler = useCallback((event) => {
  //   navigate(`/dashboard?requestType=${event.target.value}`);
  // }, []);


  const [DAU, setDAU] = useState(0)
  const [WAU, setWAU] = useState(0)
  const [MAU, setMAU] = useState(0)
  const [DAUMAURatio, setDAUMAURatio] = useState(0)
  const [WAUMAURatio, setWAUMAURatio] = useState(0)
  const [DAUByTotalUsers, setDAUByTotalUsers] = useState(0)
  const [WAUByTotalUsers, setWAUByTotalUsers] = useState(0)
  const [MAUByTotalUsers, setMAUByTotalUsers] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [NumberofMinutesPerDay, setNumberofMinutesPerDay] = useState(0)
  const [NumberofHoursPerDay, setNumberofHoursPerDay] = useState(0)
  const [NumberofHoursPerWeek, setNumberofHoursPerWeek] = useState(0)
  const [NumberofHoursPerMonth, setNumberofHoursPerMonth] = useState(0)
  const [NumberofAppOpensPerDay, setNumberofAppOpensPerDay] = useState(0)
  const [NumberofAppOpensPerWeek, setNumberofAppOpensPerWeek] = useState(0)
  const [NumberofAppOpensPerMonth, setNumberofAppOpensPerMonth] = useState(0)
  const [TotalNumberOfSessionsPerDay, setTotalNumberOfSessionsPerDay] = useState(0)
  const [selectedDateForMinutes, setSelectedDateForMinutes] = useState(date);
  const [selectedDateForSessions, setSelectedDateForSeesions] = useState(date);
  const [NumberOfMinutesPerSession, setNumberOfMinutesPerSession] = useState(0)


  const getActiveUserAndUpdate = async (days) => {
    try {
      let res = await getActiveUsers(days);
      switch (days) {
        case 1:
          setDAU(res?.data?.data.totalUniqueUserCount)
          break;
        case 7:
          setWAU(res?.data?.data.totalUniqueUserCount)
          break;
        case 30:
          setMAU(res?.data?.data.totalUniqueUserCount)
          break
      }
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getNumberofHoursAndUpdate = async (days) => {
    try {
      let res = await getNumberOfHours(days);
      switch (days) {
        case 1:
          setNumberofHoursPerDay(res.data.data.totalHours.toFixed(1))
          break;
        case 7:
          setNumberofHoursPerWeek(res.data.data.totalHours.toFixed(1))
          break;
        case 30:
          setNumberofHoursPerMonth(res.data.data.totalHours.toFixed(1))
          break
      }
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getNumberofMinutesPerSessionAndUpdate = async (days) => {
    try {
      let res = await getNumberOfMinutesPerSession()
      setNumberOfMinutesPerSession(res?.data?.data?.averageMinutesPerSession?.toFixed(2))
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };


  const getNumberofAppOpensAndUpdate = async (days) => {
    try {
      let res = await getNumberofAppOpens(days);
      switch (days) {
        case 1:
          setNumberofAppOpensPerDay(res.data.data.totalNumberOfAppOpens)
          break;
        case 7:
          setNumberofAppOpensPerWeek(res.data.data.totalNumberOfAppOpens)
          break;
        case 30:
          setNumberofAppOpensPerMonth(res.data.data.totalNumberOfAppOpens)
          break
      }
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };
  //   try {
  //     let res = await getNumberofAppOpens(days);
  //     switch (days) {
  //       case 1:
  //         setNumberofAppOpensPerDay(res.data.data.totalNumberOfAppOpens)
  //         break;
  //         case 7:
  //           setNumberofAppOpensPerWeek(res.data.data.totalNumberOfAppOpens)
  //         break;
  //         case 30:
  //           setNumberofAppOpensPerMonth(res.data.data.totalNumberOfAppOpens)
  //         break
  //     }
  //   } catch (err) {
  //     if (err?.response?.status === 401) {
  //     } else console.log(err);
  //   }
  // };

  const getNumberofMinutesAndUpdate = async (days) => {
    try {
      let res = await getMinutesPerDay(days);
      console.log(res?.data?.data)
      setNumberofMinutesPerDay(res?.data?.data.totalMinutes.toFixed(2))
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getDAUMAUratio = async (days) => {
    try {
      let res = await getDAUByMAURatio()
      setDAUMAURatio(res?.data?.data.toFixed())
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getWAUMAUratio = async (days) => {
    try {
      let res = await getWAUByMAURatio()
      setWAUMAURatio(res?.data?.data.toFixed(2))
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getDAUByTotalUsersAndUpdate = async (days) => {
    try {
      let res = await getDAUByTotalUsers()
      console.log(res?.data?.data)
      setDAUByTotalUsers(res?.data?.data.toFixed(2))
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getMAUByTotalUsersAndUpdate = async (days) => {
    try {
      let res = await getMAUByTotalUsers()
      console.log(res?.data?.data)
      setMAUByTotalUsers(res?.data?.data.toFixed(2))
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };


  const getWAUByTotalUsersAndUpdate = async (days) => {
    try {
      let res = await getWAUByTotalUsers()
      console.log(res?.data?.data)
      setWAUByTotalUsers(res?.data?.data.toFixed(2))
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getTotalUsersAndUpdate = async () => {
    try {
      let res = await getTotalUsers()
      setTotalUsers(res?.data?.data.totalUsers)
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };

  const getTotalNumberOfSessionsPerDay = async (date) => {
    try {
      let res = await getSessionsPerDay(date)
      setTotalNumberOfSessionsPerDay(res?.data?.data?.sessionCount)
    } catch (err) {
      if (err?.response?.status === 401) {
      } else console.log(err);
    }
  };
 

  useEffect(() => {
    getActiveUserAndUpdate(day);
    getActiveUserAndUpdate(week);
    getActiveUserAndUpdate(month);
    getNumberofHoursAndUpdate(day)
    getNumberofHoursAndUpdate(week)
    getNumberofHoursAndUpdate(month)
    getNumberofAppOpensAndUpdate(day)
    getNumberofAppOpensAndUpdate(month)
    getNumberofAppOpensAndUpdate(week)
    getDAUMAUratio()
    getWAUMAUratio()
    getDAUByTotalUsersAndUpdate()
    getMAUByTotalUsersAndUpdate()
    getWAUByTotalUsersAndUpdate()
    getTotalUsersAndUpdate()
    getNumberofMinutesPerSessionAndUpdate()

  }, [])

  useEffect(() => {
    getNumberofMinutesAndUpdate(selectedDateForMinutes)
  }, [selectedDateForMinutes])

  useEffect(() => {
    getTotalNumberOfSessionsPerDay(selectedDateForSessions)
  }, [selectedDateForSessions])

  return (
    <div className="section dashboard">
      <div className="table-header">
      </div>
      <div className="cards-wrapper">
        <div className="wrapper">
          <div className="card">
            <h3>Daily Active Users (DAU)</h3>
            <div className="chart users">
              <span>{DAU}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Weekly Active Users (WAU)</h3>
            <div className="chart users">
              <span>{WAU}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Monthly Active Users (MAU)</h3>
            <div className="chart users">
              <span>{MAU}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Total Users</h3>
            <div className="chart users">
              <span>{totalUsers}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>DAU / MAU Ratio</h3>
            <div className="chart users">
              <span>{DAUMAURatio}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>DAU / Total Users</h3>
            <div className="chart users">
              <span>{DAUByTotalUsers}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>WAU / MAU Ratio</h3>
            <div className="chart users">
              <span>{WAUMAURatio}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>WAU / Total Users </h3>
            <div className="chart users">
              <span>{WAUByTotalUsers}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>MAU / Total Users</h3>
            <div className="chart users">
              <span>{MAUByTotalUsers}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <div className="card-header">
              <h3>Number of Minutes Per Day</h3>
              <div className="input-wrapper">
                <DatePicker
                  selected={selectedDateForMinutes}
                  onChange={(date) => setSelectedDateForMinutes(date)}
                  dateFormat="yyyy-MM-dd"  // Formats date as yyyy-mm-dd
                  dropdownMode="select"  // Makes the calendar open like a dropdown
                  maxDate={date}
                />
              </div>
            </div>
            <div className="card-content">
              <div className="chart">
                <span>{NumberofMinutesPerDay}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Number of Hours Per Day</h3>
            <div className="chart users">
              <span>{NumberofHoursPerDay}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Number of Hours Per Week</h3>
            <div className="chart users">
              <span>{NumberofHoursPerWeek}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Number of Hours Per Month</h3>
            <div className="chart users">
              <span>{NumberofHoursPerMonth}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <div className="card-header">
              <h3>Total Number of Sessions Per Day</h3>
              <div className="input-wrapper">
                <DatePicker
                  selected={selectedDateForSessions}
                  onChange={(date) => setSelectedDateForSeesions(date)}
                  dateFormat="yyyy-MM-dd"  // Formats date as yyyy-mm-dd
                  dropdownMode="select"    // Makes the calendar open like a dropdown
                  maxDate={date}
                />
              </div>
            </div>

            <div className="card-content">
              <div className="chart">
                <span>{TotalNumberOfSessionsPerDay}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Average Number of Minutes Per Session</h3>
            <div className="chart users">
              <span>{NumberOfMinutesPerSession}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Number of App Opens Per Day</h3>
            <div className="chart users">
              <span>{NumberofAppOpensPerDay}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Number of App Opens Per Week</h3>
            <div className="chart users">
              <span>{NumberofAppOpensPerWeek}</span>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>Number of App Opens Per Month</h3>
            <div className="chart users">
              <span>{NumberofAppOpensPerMonth}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="section dashboard">
    //   <div className="table-header">
    //     <div className="input-wrapper">
    //       <select
    //         value={
    //           search
    //             ?.split("?")[1]
    //             ?.split("&")
    //             ?.filter((el) => el.includes("requestType"))[0]
    //             ?.split("=")[1]
    //             ?.split("%20")
    //             .join(" ") || "Today"
    //         }
    //         onChange={changehandler}
    //       >
    //         <option value="Today">Today</option>
    //         <option value="Last 7 days">Last 7 days</option>
    //         <option value="Last 30 days">Last 30 days</option>
    //         <option value="Last 90 days">Last 90 days</option>
    //         <option value="Last 1 year">Last 1 year</option>
    //       </select>
    //     </div>
    //   </div>
    //   <div className="cards-wrapper">
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>USERS</h3>
    //         <div className="chart users">
    //           <span>{data?.users?.total}</span>

    //           {data?.users?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.users?.analytics?.map((item, index) => (
    //                 <li key={`dashboard=${index}`}>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.users) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>ORDERS</h3>
    //         <div className="chart orders">
    //           <span>{data?.orders?.total}</span>
    //           {data?.orders?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.orders?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.orders) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>APPOINTMENTS</h3>
    //         <div className="chart appointments">
    //           <span>{data?.appointments?.total}</span>
    //           {data?.appointments?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.appointments?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.appointments) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>APP DOWNLOADS</h3>
    //         <div className="chart users">
    //           <span>{data?.downloads?.total}</span>
    //           {data?.downloads?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.downloads?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.downloads) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>ONLINE USERS</h3>
    //         <div className="chart orders">
    //           <span>{data?.onlineUsers?.total}</span>
    //           {data?.onlineUsers?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.onlineUsers?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.onlineUsers) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>CATEGORY WISE APPOINTMENTS</h3>
    //         <div className="chart orders">
    //           <span>{data?.categoryWiseAppointments?.total}</span>
    //           {data?.categoryWiseAppointments?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.categoryWiseAppointments?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item._id}</p>
    //                   <div
    //                     style={{
    //                       height: `${
    //                         (100 / max.categoryWiseAppointments) * item.total
    //                       }%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>Number of Items</h3>
    //         <div className="chart orders">
    //           <span>{data?.onlineUsers?.total}</span>
    //           {data?.onlineUsers?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.onlineUsers?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.onlineUsers) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>Number of Outfits</h3>
    //         <div className="chart orders">
    //           <span>{data?.onlineUsers?.total}</span>
    //           {data?.onlineUsers?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.onlineUsers?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.onlineUsers) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>

    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>Most Worn Items</h3>
    //         <div className="chart orders">
    //           <span>{data?.onlineUsers?.total}</span>
    //           {data?.onlineUsers?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.onlineUsers?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.onlineUsers) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>

    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>Least Worn Outfits</h3>
    //         <div className="chart orders">
    //           <span>{data?.onlineUsers?.total}</span>
    //           {data?.onlineUsers?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.onlineUsers?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.onlineUsers) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>Top Colors Wardrobe</h3>
    //         <div className="chart orders">
    //           <span>{data?.onlineUsers?.total}</span>
    //           {data?.onlineUsers?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.onlineUsers?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.onlineUsers) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>

    //     <div className="wrapper">
    //       <div className="card">
    //         <h3>Top Color Outfits</h3>
    //         <div className="chart orders">
    //           <span>{data?.onlineUsers?.total}</span>
    //           {data?.onlineUsers?.analytics?.length > 0 && (
    //             <ul>
    //               {data?.onlineUsers?.analytics?.map((item) => (
    //                 <li>
    //                   <span>{item.total}</span>
    //                   <p>{item.date}</p>
    //                   <div
    //                     style={{
    //                       height: `${(100 / max.onlineUsers) * item.total}%`,
    //                     }}
    //                   />
    //                 </li>
    //               ))}
    //             </ul>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(Dashboard);
